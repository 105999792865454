import React from "react";
import { Course } from "../Search/SearchCourses";
import ContainerCard from "./StyleContainerCard";

const CardCourse = ({ course }: { course: Course }) => (
    <ContainerCard>
        <div className="container-card">
            <a
                href={course.short_url}
                className="clickable-element cover-container"
            >
                {course.cover && (
                    <div className="container-cover">
                        <div className="overlay" />
                        <img className="cover" src={course.cover} alt="" />
                    </div>
                )}
            </a>
            <a
                href={course.short_url}
                className="card-title clickable-element"
                style={{ marginTop: "20px" }}
            >
                {course.name}
            </a>

            {course.talk_count > 0 ? (
                <p className="card-date">
                    {course.talk_count}{" "}
                    {course.talk_count === 1 ? (
                        <span>Transmissão</span>
                    ) : (
                        <span>Transmissões</span>
                    )}
                </p>
            ) : (
                <p>Ainda não há transmissões</p>
            )}
        </div>
    </ContainerCard>
);

export default CardCourse;
