import React from "react";
import styled from "styled-components";
import {
    EventialsLogoWhite,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    TwitterIcon,
} from "./SVG";

const ContainerFooter = styled.div`
    position: relative;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 140px;
    margin-top: 70px;

    font-size: 12px;
    font-weight: 500;

    

    ul {
        list-style-type: none;
    }

    .list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .list-item {
            padding: 0 10px;
        }
    }

    .footer-about-us {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 95px;
        padding: 0 100px;
        width: 100%;

        background-color: ${(props) => props.theme.colors.greyFooter};

        a {
            cursor: pointer;
            text-decoration: none;
            color: ${(props) => props.theme.colors.white};
            opacity: 0.8;
            font-weight: 400;
        }

        .icon {
            svg {
                height: 22px;
                width: auto;
            }
        }
    }

    .footer-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 45px;
        padding: 0 100px;
        font-size: 12px;

        a {
            cursor: pointer;
            text-decoration: none;
            color: ${(props) => props.theme.colors.black};
            opacity: 0.8;
        }
    }

    @media screen and (max-width: 1080px) {
        height: auto;

        .list {
            height: auto;
            padding: 5px;
            display: flex;
            flex-wrap: wrap;
        }

        .footer-about-us {
            height: auto;
            padding: 3px;
            display: flex;
            flex-wrap: wrap;

            .list-item {
                margin-bottom: 15px;
                display: block;
            }
        }

        .footer-info {
            height: auto;
            padding: 3px;
            display: flex;
            flex-wrap: wrap;

            .list-item {
                height: auto;
                margin-bottom: 15px;
                display: block;
            }
        }
    }

    @media screen and (max-width: 600px) {
        display: flex;
        flex-flow: row;

        li {
            display: block;
        }

        .footer-about-us {
            height: auto;
            padding: 3px;
            display: block;

            .list{
              display: flex;
              width: 100%;
              flex-direction: column;
            }

            .list-item {
                margin-bottom: 15px;
                display: block;
            }

            .social-medias{
              .list{
                flex-direction: row;
              }
            }
        }

        .footer-info {
            height: auto;
            padding: 3px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .list{
              display: flex;
              width: 100%;
            }
        }
    }
`;

const Footer = () => {
    return (
        <>
            <ContainerFooter>
                <div className="footer">
                    <div className="footer-about-us">
                        <ul className="list">
                            <li className="list-item">
                                <a href="https://www.eventials.com/">
                                    Sobre Nós
                                </a>
                            </li>
                            <li className="list-item">
                                <a href="https://www.eventials.com/">
                                    Plataforma
                                </a>
                            </li>
                            <li className="list-item">
                                <a href="https://suporte.eventials.com/kb">
                                    Suporte
                                </a>
                            </li>
                            <li className="list-item">
                                <a href="https://eventialsapi.docs.apiary.io/#">
                                    API
                                </a>
                            </li>
                            <li className="list-item">
                                <a href="https://status.eventials.com/">
                                    Status
                                </a>
                            </li>
                            <li className="list-item">
                                <a href="https://blog.eventials.com/">Blog</a>
                            </li>
                            <li className="list-item">
                                <a href="https://www.eventials.com/">
                                    Contato
                                </a>
                            </li>
                        </ul>

                        <div className="social-medias">
                            <ul className="list">
                                <li className="list-item">
                                    <a
                                        href="http://www.facebook.com/eventials"
                                        className="icon"
                                    >
                                        <FacebookIcon />
                                    </a>
                                </li>
                                <li className="list-item">
                                    <a
                                        href="http://twitter.com/eventials"
                                        className="icon"
                                    >
                                        <TwitterIcon />
                                    </a>
                                </li>
                                <li className="list-item">
                                    <a
                                        href="https://www.instagram.com/eventials.webinars/"
                                        className="icon"
                                    >
                                        <InstagramIcon />
                                    </a>
                                </li>
                                <li className="list-item">
                                    <a
                                        href="https://www.eventials.com/eventials/"
                                        className="icon"
                                    >
                                        <EventialsLogoWhite />
                                    </a>
                                </li>
                                <li className="list-item">
                                    <a
                                        href="https://www.linkedin.com/company/eventials/"
                                        className="icon"
                                    >
                                        <LinkedinIcon />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-info">
                        <ul className="list">
                            <li className="list-item">
                                <a href="#ol">©2021 Eventials</a>
                            </li>
                            <li className="list-item">
                                <a href="https://docs.google.com/a/eventials.com/viewer?embedded=true&url=https://contratos.eventials.com/Eventials-Termos_Gerais_de_Uso-v1.pdf">
                                    Termos de Uso
                                </a>
                            </li>
                            <li className="list-item">
                                <a href="https://www.eventials.com/portal-de-privacidade/">
                                    Portal de Privacidade
                                </a>
                            </li>
                            <li className="list-item">
                                <a href="#ol">Política de Privacidade (PDF)</a>
                            </li>
                            <li className="list-item">
                                <a href="https://suporte.eventials.com/kb/article/160228/contratos-e-politicas-eventials">
                                    Contratos
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </ContainerFooter>
        </>
    );
};

export default Footer;
