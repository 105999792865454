import React from "react";
import { Group } from "../Search/SearchGroups";
import ContainerCard from "./StyleContainerCard";

const CardGroup = ({ group }: { group: Group }) => (
    <ContainerCard>
        <div className="container-card">
            <a
                href={group.short_url}
                className="clickable-element cover-container"
            >
                {group.cover && (
                    <div
                        className="container-cover"
                        style={{ marginBottom: "15px" }}
                    >
                        <div className="overlay" />
                        <img
                            className="cover"
                            src={group.cover}
                            alt={group.name}
                        />
                    </div>
                )}
            </a>
            <a
                href={group.short_url}
                className="card-title clickable-element"
                style={{ marginTop: "20px" }}
            >
                {group.name}
            </a>
            {group.talk_count > 0 ? (
                <p className="card-date">
                    {group.talk_count}{" "}
                    {group.talk_count === 1 ? (
                        <span>Transmissão</span>
                    ) : (
                        <span>Transmissões</span>
                    )}
                </p>
            ) : (
                <p>Ainda não há transmissões</p>
            )}
        </div>
    </ContainerCard>
);

export default CardGroup;
