import React, { useState } from "react";
import styled from "styled-components";
import GrayButton from "./Buttons/GrayButton";
import InputEventials from "../components/input/Input";
import { IconInputSearch } from "./SVG";
import { ArrowRightOutlined as IconSend} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const ContainerExplorer = styled.div`
    display: relative;
    margin-top: 80px;

    .btn-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-top: 80px;

        @media screen and (max-width: 768px) {
            margin-top: 40px;
        }

        .btn {
            display: flex;
            align-items: center;

            button {
                margin: 10px;
            }

            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }

    .input-text-container {
        width: 316px;
    }

    padding: 0 70px;
    @media screen and (max-width: 1200px) {
        padding: 0 40px;
        margin-top:40px; 
    }
    @media screen and (max-width: 768px) {
        padding: 0 20px;


        .input-text-container {
            width: 100%;
        }
    }
`;

interface IExplorerProps {
    updateSearchValue: (search: string) => void;
}

const StyledSearch = styled(InputEventials)`
    display: block;
    transition: all 0.5s;
`;

const Explorer: React.FC<IExplorerProps> = ({ updateSearchValue }) => {
    const [activeMenu, setActiveMenu] = useState<"highlights" | "schedule">(
        "highlights"
    );
    const [searchText, setSearchText] = useState<string>("");

    const history = useHistory();

    const handleEnter = () => {
        updateSearchValue(searchText);
        history.push("/search");
    };

    return (
        <ContainerExplorer>
            <h1 className="title">Explorar</h1>

            <div className="btn-bar">
                <div className="btn">
                    <GrayButton
                        active={activeMenu === "highlights"}
                        onClick={() => {
                            setActiveMenu("highlights");
                        }}
                    >
                        Destaques
                    </GrayButton>
                    <GrayButton
                        style={{ display: 'none' }}
                        active={activeMenu === "schedule"}
                        onClick={() => {
                            setActiveMenu("schedule");
                        }}
                    >
                        Agenda
                    </GrayButton>
                </div>
                <div className="input-text-container">
                    <StyledSearch
                        key="styledSearch"
                        leftIcon={<IconInputSearch />}
                        rightIcon={<IconSend onClick={handleEnter} style={{
                            animationName: searchText !== "" ? 'spin' : 'reverse-spin',
                            animationDuration: '500ms',
                            animationIterationCount: '1',
                            animationTimingFunction: 'cubic-bezier' 
                        }} />}
                        borderRadius={40}
                        placeholderText="Pesquise por título ou palavra-chave"
                        color="inputBackground"
                        backgroundColor="background"
                        onPressEnter={handleEnter}
                        onChange={(e: any) => setSearchText(e.target.value)}
                    />
                </div>
            </div>
        </ContainerExplorer>
    );
};

export default Explorer;
