import styled from "styled-components";

interface IGrayButtonProps {
    active: boolean;
}

const GrayButton = styled.button<IGrayButtonProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 10px 15px;
    min-width: 80px;
    height: 44px;
    border-radius: 30px;

    color: ${(props) => (props.active ? props.theme.colors.offWhite : props.theme.colors.body)};
    background-color: ${(props) => (props.active ? props.theme.colors.secondary : "transparent")};
    border: 1px solid ${(props) => props.theme.colors.body};
    outline: none;

    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;

    text-align: center;
    letter-spacing: 0.75px;

    cursor: pointer;
`;

export default GrayButton;
