import { Carousel } from "antd";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DefaultContext } from "../contexts/DefaultContext";

const ContainerCaroussel = styled.div`
    position: relative;
    margin-top: 60px;
    padding: 0 70px;
    width: auto;

    img {
        width: 100%;

        object-fit: cover;
        object-position: center;
    }

    .ant-carousel {
        .slick-dots {
            li button {
                width: 12px;
                height: 12px;
                border-radius: 50px;
                background: ${(props) => props.theme.colors.border};
            }

            li.slick-active {
                button {
                    background: ${(props) => props.theme.colors.placeholder};
                }
                width: 12px;
            }
        }

        .slick-dots-bottom {
            bottom: -30px;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 0px;
    }
`;

const Caroussel = () => {
    const [slides, setSlides] = useState<any>([]);
    const { banners } = useContext(DefaultContext);

    useEffect(() => {
        setSlides(banners);
    }, [banners]);

    return (
        <ContainerCaroussel>
          <Carousel autoplay key="caroussel">
              {slides.map((slide:any) => (
                  <a href={slide.url} target="_blank" key={slide.title} rel="noreferrer">
                    <img src={slide.banner} alt={slide.title} />
                  </a>
              ))}
          </Carousel>
        </ContainerCaroussel>
    );
};

export default Caroussel;
