import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Talk } from "../../App";
import ButtonCircle from "../Buttons/ButtonCircle";
import ButtonNext from "../Buttons/ButtonNext";
import ButtonReturn from "../Buttons/ButtonReturn";
import Card from "./Card";
import axios from "axios";
import { ArrowRightDefault } from "../SVG";
import ButtonNextContainer from "../Buttons/ButtonNext";
import ButtonReturnContainer from "../Buttons/ButtonReturn";

const ContainerCardsGrid = styled.div`
    display: relative;
    margin-top: 60px;

    height: fit-content;
    width: auto;

    .container-card {
        margin-right: 0;
    }

    .header-sub-title {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 13px;

        .container-arrow {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 96px;

            .arrow {
                width: 30px;
                height: 30px;
                border: 2px solid ${(props) => props.theme.colors.secondary};
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .description {
        margin-bottom: 64px;
    }

    .card-grid-area {
        width: auto;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .pages {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

const ContainerButtonPages = styled.div`
    display: relative;
    margin-top: 60px;

    justify-content: center;

    height: fit-content;
    width: 100%;

    div {
        display: flex;
        justify-content: space-between;
    }
`;

interface ICardsGridProps {
    category: string;
}

const CardsGrid = ({ category }: ICardsGridProps) => {
    const [currentPage, setPage] = useState<number>(1);
    const [talksCount, settalksCount] = useState<number>(0);
    const [page1, setPage1] = useState<number>(1);
    const [page2, setPage2] = useState<number>(2);
    const [page3, setPage3] = useState<number>(3);
    const [currentTalks, setCurrentTalks] = useState<Talk[]>([]);
    const [title, setTitle] = useState<string>("");

    useEffect(() => {
        try {
            axios.get(`/talks/?category=${category}`).then((res) => {
                setTitle(res.data.talks[0].category.name_pt_br);
                setCurrentTalks(res.data.talks);
                settalksCount(res.data.count);
            });
        } catch (err) {
            console.log(err);
        }
    }, [category]);

    const whenTalksIsNotEmpty = () => {
        return true;
    };

    const pageSelect = (page: number) => {
        try {
            setPage(page);

            axios.get(`/talks/?category=${category}&p=${page}`).then((res) => {
                setCurrentTalks(res.data.talks);
            });
            window.scrollTo(0,0);
        } catch (err) {
            console.log(err);
        }
    };

    const handleNext = () => {
        if (currentPage + 1 <= Math.ceil(talksCount / 12)) {
            if (currentPage === page3) {
                setPage1(currentPage - 1);
                setPage2(currentPage);
                setPage3(currentPage + 1);
            }

            setPage(currentPage + 1);
            axios
                .get(`/talks/?category=${category}&p=${currentPage + 1}`)
                .then((res) => {
                    setCurrentTalks(res.data.talks);
                });
            window.scrollTo(0,0);
        }
    };

    const handlePrevious = () => {
        if (currentPage - 1 >= 1) {
            if (currentPage === page1) {
                setPage1(currentPage - 1);
                setPage2(currentPage);
                setPage3(currentPage + 1);
            }

            setPage(currentPage - 1);
            axios
                .get(`/talks/?category=${category}&p=${currentPage - 1}`)
                .then((res) => {
                    setCurrentTalks(res.data.talks);
                });
            window.scrollTo(0,0);
        }
    };

    return (
        <ContainerCardsGrid>
            <div className="container">
                <div>
                    <div className="header-sub-title">
                        <div
                            className="sub-title"
                            style={{ marginBottom: "45px", marginLeft: "10px" }}
                        >
                            <span>{title}</span>
                        </div>
                    </div>
                </div>
                {whenTalksIsNotEmpty() ? (
                    <div className="card-grid-area">
                        {currentTalks.map((talk: Talk) => (
                            <div className="card-item" key={talk.id}>
                                <Card talk={talk} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <p
                        style={{
                            textAlign: "center",
                            padding: "50px",
                            fontWeight: "bold",
                        }}
                    >
                        Nenhum resultado encontrado :(
                    </p>
                )}

                {talksCount > 0 && (
                    <ContainerButtonPages>
                        <div className="pages">
                            {talksCount / 12 <= 1 && (
                                <div>
                                    <ButtonCircle
                                        onClick={() => pageSelect(page1)}
                                        active={page1 === currentPage}
                                    >
                                        {page1}
                                    </ButtonCircle>
                                </div>
                            )}
                            {talksCount / 12 > 1 && talksCount / 12 <= 2 && (
                                <div>
                                    <ButtonReturn />
                                    <div>
                                        <ButtonCircle
                                            onClick={() => pageSelect(page1)}
                                            active={page1 === currentPage}
                                        >
                                            {page1}
                                        </ButtonCircle>
                                        <ButtonCircle
                                            active={page2 === currentPage}
                                            onClick={() => pageSelect(page2)}
                                        >
                                            {page2}
                                        </ButtonCircle>
                                    </div>
                                    <ButtonNext />
                                </div>
                            )}
                            {talksCount / 12 > 2 && talksCount / 12 <= 3 && (
                                <div>
                                    <ButtonReturn />
                                    <div>
                                        <ButtonCircle
                                            active={page1 === currentPage}
                                            onClick={() => pageSelect(page1)}
                                        >
                                            {page1}
                                        </ButtonCircle>
                                        <ButtonCircle
                                            active={page2 === currentPage}
                                            onClick={() => pageSelect(page2)}
                                        >
                                            {page2}
                                        </ButtonCircle>
                                        <ButtonCircle
                                            active={page3 === currentPage}
                                            onClick={() => pageSelect(page3)}
                                        >
                                            {page3}
                                        </ButtonCircle>
                                    </div>
                                    <ButtonNext />
                                </div>
                            )}
                            {talksCount / 12 > 3 && (
                                <div>
                                    <ButtonReturnContainer
                                        onClick={handlePrevious}
                                    >
                                        <div>
                                            <span className="icon">
                                                <ArrowRightDefault />
                                            </span>
                                            <span className="text">
                                                Anterior
                                            </span>
                                        </div>
                                    </ButtonReturnContainer>
                                    <div>
                                        <ButtonCircle
                                            active={page1 === currentPage}
                                            onClick={() => pageSelect(page1)}
                                        >
                                            {page1}
                                        </ButtonCircle>
                                        <ButtonCircle
                                            active={page2 === currentPage}
                                            onClick={() => pageSelect(page2)}
                                        >
                                            {page2}
                                        </ButtonCircle>
                                        <ButtonCircle
                                            active={page3 === currentPage}
                                            onClick={() => pageSelect(page3)}
                                        >
                                            {page3}
                                        </ButtonCircle>
                                    </div>
                                    <ButtonNextContainer onClick={handleNext}>
                                        <div>
                                            <span className="text">
                                                Próxima
                                            </span>
                                            <span className="icon">
                                                <ArrowRightDefault />
                                            </span>
                                        </div>
                                    </ButtonNextContainer>
                                </div>
                            )}
                        </div>
                    </ContainerButtonPages>
                )}
            </div>
        </ContainerCardsGrid>
    );
};

export default CardsGrid;
