import React from "react";
import styled from "styled-components";
import { Input as AntInput } from "antd";
import defaultTheme from "../../theme/theme";

interface StyledInputProps {
    style?: any;
    color: string;
    borderRadius?: number;
    backgroundColor: string;
    inputColor: string;
}

export interface IInput {
    style?: any;
    color: string;
    error?: string;
    onChange?: any;
    onPressEnter?: any;
    borderRadius?: number;
    leftIcon?: any;
    placeholderText?: string;
    backgroundColor: string;
    inputColor: string;
    value?: any;
    searchString?: string;
    rightIcon?: any;
}

const getBorderRadius = ({ borderRadius }: StyledInputProps): number => {
    if (!borderRadius) return 8;

    return borderRadius;
};

const StyledInput = styled.div<StyledInputProps>`
    .ant-input-affix-wrapper,
    > input {
        border-radius: ${getBorderRadius}px;
        border-color: ${(props) => props.theme.colors.border};
        padding: 11px;
        background-color: ${defaultTheme.colors.background};
    }

    input {
        color: ${(props) => props.theme.colors.title};
        background-color: ${(props) => props.theme.colors.inputBackground};

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: ${(props) => props.theme.colors.placeholder};
            opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: ${(props) => props.theme.colors.placeholder};
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: ${(props) => props.theme.colors.placeholder};
        }
    }

    .ant-input-affix-wrapper:hover,
    input:hover {
        border-color: ${(props) => props.theme.colors.border};
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-input:focus,
    .ant-input-focused {
        box-shadow: 0 0;
    }
`;

const Input = ({
    style,
    color,
    error,
    borderRadius,
    leftIcon,
    rightIcon,
    onChange,
    onPressEnter,
    placeholderText,
    backgroundColor,
    value,
    inputColor,
    searchString,
    ...rest
}: IInput) => (
    <StyledInput
        inputColor={inputColor}
        borderRadius={borderRadius}
        backgroundColor={backgroundColor}
        color={color}
        style={{ ...style }}
        {...rest}
    >
        <AntInput
            placeholder={placeholderText}
            prefix={leftIcon}
            onChange={onChange}
            onPressEnter={onPressEnter}
            suffix={rightIcon ? rightIcon: null}
        />

        {/* <Typography type="body4" color="error">
      {error}
    </Typography> */}
    </StyledInput>
);

Input.defaultProps = {
    color: "placeholder",
    backgroundColor: "white",
    inputColor: "title",
};

export default Input;
