import React from "react";
import colors from "../theme/colors";

export const ArrowRightDefault = () => (
    <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.3071 3.9585L16.6248 9.50016L11.3071 15.0418"
            stroke={colors.secondary}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="15.6636"
            y1="9.4834"
            x2="3.1249"
            y2="9.4834"
            stroke={colors.secondary}
            strokeWidth="1.5"
            strokeLinecap="round"
        />
    </svg>
);

export const ArrowRight = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.48782 8.98781L12.5218 8.98781L7.7012 13.8084C7.31595 14.1936 7.31595 14.8258 7.7012 15.2111C8.08645 15.5963 8.70877 15.5963 9.09402 15.2111L15.6037 8.70134C15.989 8.31609 15.989 7.69377 15.6037 7.30852L9.1039 0.788922C8.91934 0.603951 8.66878 0.5 8.40749 0.5C8.14619 0.5 7.89563 0.603951 7.71107 0.788922C7.32583 1.17417 7.32583 1.7965 7.71107 2.18175L12.5218 7.01217L1.48782 7.01217C0.944518 7.01217 0.5 7.45669 0.5 7.99999C0.5 8.54329 0.944518 8.98781 1.48782 8.98781Z"
            fill={colors.secondary}
        />
    </svg>
);

export const ArrowRightDisabled = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.48782 8.98781L12.5218 8.98781L7.7012 13.8084C7.31595 14.1936 7.31595 14.8258 7.7012 15.2111C8.08645 15.5963 8.70877 15.5963 9.09402 15.2111L15.6037 8.70134C15.989 8.31609 15.989 7.69377 15.6037 7.30852L9.1039 0.788922C8.91934 0.603951 8.66878 0.5 8.40749 0.5C8.14619 0.5 7.89563 0.603951 7.71107 0.788922C7.32583 1.17417 7.32583 1.7965 7.71107 2.18175L12.5218 7.01217L1.48782 7.01217C0.944518 7.01217 0.5 7.45669 0.5 7.99999C0.5 8.54329 0.944518 8.98781 1.48782 8.98781Z"
            fill={colors.line}
        />
    </svg>
);

export const ArrowLeft = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.5122 7.01219L3.47825 7.01219L8.2988 2.19164C8.68405 1.80639 8.68405 1.17419 8.29881 0.788937C7.91356 0.403688 7.29123 0.403688 6.90598 0.788937L0.396257 7.29866C0.011008 7.6839 0.0110079 8.30623 0.396257 8.69148L6.8961 15.2111C7.08066 15.396 7.33122 15.5 7.59251 15.5C7.85381 15.5 8.10437 15.396 8.28893 15.2111C8.67417 14.8258 8.67417 14.2035 8.28893 13.8183L3.47825 8.98782L14.5122 8.98783C15.0555 8.98783 15.5 8.54331 15.5 8.00001C15.5 7.45671 15.0555 7.01219 14.5122 7.01219Z"
            fill={colors.secondary}
        />
    </svg>
);

export const ArrowLeftDisabled = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.5122 7.01219L3.47825 7.01219L8.2988 2.19164C8.68405 1.80639 8.68405 1.17419 8.29881 0.788937C7.91356 0.403688 7.29123 0.403688 6.90598 0.788937L0.396257 7.29866C0.011008 7.6839 0.0110079 8.30623 0.396257 8.69148L6.8961 15.2111C7.08066 15.396 7.33122 15.5 7.59251 15.5C7.85381 15.5 8.10437 15.396 8.28893 15.2111C8.67417 14.8258 8.67417 14.2035 8.28893 13.8183L3.47825 8.98782L14.5122 8.98783C15.0555 8.98783 15.5 8.54331 15.5 8.00001C15.5 7.45671 15.0555 7.01219 14.5122 7.01219Z"
            fill={colors.line}
        />
    </svg>
);

export const EventialsLogo = () => (
    <svg 
        width="24" 
        height="24" 
        viewBox="0 0 52 51" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.58576C0 3.36865 2.50834 0.736281 5.65774 0.648369L25.6903 0.0891113L45.7228 0.648369C48.8722 0.736281 51.3806 3.36865 51.3806 6.58576V37.1498C51.3806 40.3669 48.8722 42.9992 45.7228 43.0872L30.1222 43.5227C30.3641 45.2024 31.2268 46.7325 32.5412 47.79C32.7958 47.9949 32.9611 48.2999 32.9611 48.6309V49.0911C32.9611 49.6378 32.527 50.081 31.9917 50.081H19.3889C18.8535 50.081 18.4194 49.6378 18.4194 49.0911V48.6309C18.4194 48.2999 18.5848 47.9949 18.8393 47.79C20.1537 46.7325 21.0164 45.2024 21.2583 43.5227L5.65774 43.0872C2.50834 42.9992 0 40.3669 0 37.1498V6.58576ZM15.1195 10.8963L38.2019 21.2083C38.9764 21.5543 38.9764 22.6762 38.2019 23.0222L15.1195 33.3342C14.6428 33.5472 14.2172 32.948 14.5634 32.5514L19.0735 27.3851C21.6952 24.3819 21.6952 19.8486 19.0735 16.8454L14.5634 11.6791C14.2172 11.2825 14.6428 10.6833 15.1195 10.8963Z" fill={colors.primary}/>
    </svg>
);

export const LogoEventialsLong = () => (
    <svg viewBox="0 0 343 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M90.6875 49.2976C87.1569 49.2976 84.1087 48.5768 81.5429 47.1354C78.9771 45.6939 77.0065 43.645 75.6312 40.9886C74.2765 38.3117 73.5991 35.1508 73.5991 31.506C73.5991 27.9641 74.2765 24.8547 75.6312 22.1777C77.0065 19.5008 78.936 17.4106 81.4197 15.9074C83.924 14.4042 86.8593 13.6526 90.2256 13.6526C92.5041 13.6526 94.6285 14.0232 96.5991 14.7646C98.5696 15.4853 100.294 16.5767 101.772 18.0387C103.25 19.4802 104.399 21.3026 105.22 23.5059C106.041 25.6887 106.452 28.2524 106.452 31.1971V33.7917H77.3863V27.892H101.833L97.3996 29.4365C97.3996 27.6861 97.1328 26.1623 96.5991 24.865C96.0654 23.5677 95.2649 22.569 94.1975 21.8688C93.1506 21.1481 91.8472 20.7878 90.2872 20.7878C88.7272 20.7878 87.4032 21.1481 86.3153 21.8688C85.2479 22.5896 84.4269 23.5677 83.8521 24.8032C83.2774 26.0388 82.99 27.4596 82.99 29.0658V33.2357C82.99 35.1096 83.3184 36.7158 83.9753 38.0543C84.6321 39.3722 85.5456 40.3915 86.7156 41.1122C87.9061 41.8123 89.2711 42.1624 90.8106 42.1624C91.878 42.1624 92.8427 42.008 93.7049 41.6991C94.567 41.3902 95.3059 40.9372 95.9217 40.34C96.558 39.7428 97.0302 39.0221 97.3381 38.1778L106.144 38.7647C105.713 40.8857 104.81 42.739 103.434 44.3246C102.08 45.8896 100.325 47.1148 98.1694 48.0003C96.0141 48.8651 93.5201 49.2976 90.6875 49.2976Z" fill={colors.primary}/>
        <path d="M142.721 14.085L130.713 48.618H119.937L107.959 14.085H118.028L125.263 38.8265H125.633L132.745 14.085H142.721Z" fill={colors.primary}/>
        <path d="M161.44 49.2976C157.909 49.2976 154.861 48.5768 152.295 47.1354C149.73 45.6939 147.759 43.645 146.384 40.9886C145.029 38.3117 144.352 35.1508 144.352 31.506C144.352 27.9641 145.029 24.8547 146.384 22.1777C147.759 19.5008 149.688 17.4106 152.172 15.9074C154.676 14.4042 157.612 13.6526 160.978 13.6526C163.257 13.6526 165.381 14.0232 167.352 14.7646C169.322 15.4853 171.046 16.5767 172.524 18.0387C174.002 19.4802 175.152 21.3026 175.973 23.5059C176.794 25.6887 177.204 28.2524 177.204 31.1971V33.7917H148.139V27.892H172.586L168.152 29.4365C168.152 27.6861 167.885 26.1623 167.352 24.865C166.818 23.5677 166.017 22.569 164.95 21.8688C163.903 21.1481 162.6 20.7878 161.04 20.7878C159.48 20.7878 158.156 21.1481 157.068 21.8688C156 22.5896 155.179 23.5677 154.605 24.8032C154.03 26.0388 153.742 27.4596 153.742 29.0658V33.2357C153.742 35.1096 154.071 36.7158 154.728 38.0543C155.385 39.3722 156.298 40.3915 157.468 41.1122C158.659 41.8123 160.024 42.1624 161.563 42.1624C162.63 42.1624 163.595 42.008 164.457 41.6991C165.319 41.3902 166.058 40.9372 166.674 40.34C167.311 39.7428 167.783 39.0221 168.091 38.1778L176.896 38.7647C176.465 40.8857 175.562 42.739 174.187 44.3246C172.832 45.8896 171.077 47.1148 168.922 48.0003C166.767 48.8651 164.273 49.2976 161.44 49.2976Z" fill={colors.primary}/>
        <path d="M192.075 28.6643V48.618H182.56V14.085H191.921V20.2318H192.259C193.039 18.1932 194.281 16.587 195.985 15.4132C197.689 14.2395 199.823 13.6526 202.389 13.6526C204.77 13.6526 206.843 14.1777 208.609 15.2279C210.394 16.2781 211.78 17.771 212.765 19.7067C213.751 21.6423 214.243 23.9589 214.243 26.6565V48.618H204.729V28.3554C204.729 26.255 204.185 24.6076 203.097 23.4133C202.009 22.1983 200.511 21.5909 198.602 21.5909C197.329 21.5909 196.2 21.8791 195.215 22.4557C194.23 23.0117 193.46 23.8148 192.906 24.865C192.352 25.9152 192.075 27.1816 192.075 28.6643Z" fill={colors.primary}/>
        <path d="M239.397 14.085V21.3129H218.676V14.085H239.397ZM223.387 5.86876H232.901V37.9925C232.901 39.1456 233.157 40.0002 233.67 40.5562C234.183 41.0916 235.005 41.3593 236.134 41.3593C236.503 41.3593 236.914 41.3284 237.365 41.2666C237.817 41.2049 238.207 41.1328 238.535 41.0504L240.044 48.2165C238.997 48.5254 237.981 48.7519 236.996 48.896C236.031 49.0402 235.076 49.1122 234.132 49.1122C230.704 49.1122 228.056 48.2577 226.188 46.5485C224.32 44.8188 223.387 42.3889 223.387 39.2589V5.86876Z" fill={colors.primary}/>
        <path d="M244.815 48.618V14.085H254.329V48.618H244.815ZM249.557 9.66801C248.14 9.66801 246.929 9.19439 245.923 8.24715C244.918 7.29991 244.415 6.15705 244.415 4.81856C244.415 3.48007 244.918 2.3475 245.923 1.42086C246.929 0.473619 248.14 0 249.557 0C250.993 0 252.215 0.473619 253.221 1.42086C254.226 2.3475 254.729 3.48007 254.729 4.81856C254.729 6.15705 254.226 7.29991 253.221 8.24715C252.215 9.19439 250.993 9.66801 249.557 9.66801Z" fill={colors.primary}/>
        <path d="M271.016 49.2667C268.84 49.2667 266.88 48.8857 265.135 48.1238C263.411 47.3413 262.056 46.1882 261.071 44.6643C260.085 43.1405 259.593 41.246 259.593 38.9809C259.593 37.0452 259.942 35.4391 260.64 34.1624C261.338 32.865 262.292 31.8148 263.503 31.0118C264.714 30.2087 266.09 29.6012 267.629 29.1894C269.168 28.7775 270.8 28.4892 272.525 28.3245C274.516 28.1392 276.117 27.9538 277.328 27.7685C278.539 27.5832 279.421 27.3052 279.976 26.9345C280.55 26.5433 280.838 25.9873 280.838 25.2665V25.1121C280.838 23.6913 280.386 22.5896 279.483 21.8071C278.6 21.0246 277.348 20.6333 275.727 20.6333C274.023 20.6333 272.658 21.0143 271.632 21.7762C270.626 22.5175 269.959 23.4647 269.63 24.6179L260.794 23.9075C261.245 21.8071 262.128 19.995 263.442 18.4711C264.755 16.9267 266.449 15.7427 268.522 14.919C270.616 14.0747 273.038 13.6526 275.788 13.6526C277.697 13.6526 279.524 13.8791 281.269 14.3321C283.014 14.7852 284.563 15.4853 285.918 16.4325C287.293 17.3592 288.381 18.5638 289.182 20.0464C289.982 21.5291 290.383 23.2897 290.383 25.3283V48.618H281.3V43.7995H281.053C280.499 44.8909 279.76 45.8484 278.836 46.6721C277.913 47.4752 276.804 48.1135 275.511 48.5871C274.218 49.0402 272.72 49.2667 271.016 49.2667ZM273.756 42.6566C275.152 42.6566 276.384 42.3786 277.451 41.8226C278.539 41.246 279.38 40.4841 279.976 39.5369C280.591 38.5691 280.899 37.488 280.899 36.2936V32.6179C280.612 32.8033 280.201 32.9783 279.668 33.143C279.155 33.3078 278.58 33.4519 277.944 33.5755C277.307 33.699 276.671 33.8123 276.035 33.9152C275.398 33.9976 274.824 34.08 274.31 34.1624C273.181 34.3271 272.196 34.5948 271.355 34.9654C270.513 35.3155 269.856 35.7994 269.384 36.4172C268.932 37.0144 268.707 37.766 268.707 38.672C268.707 39.9693 269.179 40.9578 270.123 41.6373C271.067 42.3168 272.278 42.6566 273.756 42.6566Z" fill={colors.primary}/>
        <path d="M306.731 2.59461V48.618H297.217V2.59461H306.731Z" fill={colors.primary}/>
        <path d="M342.199 23.9384L333.486 24.4635C333.26 23.3721 332.655 22.4351 331.669 21.6526C330.684 20.8495 329.36 20.448 327.697 20.448C326.22 20.448 324.978 20.7569 323.972 21.3746C322.987 21.9924 322.494 22.8161 322.494 23.8457C322.494 24.6694 322.812 25.3798 323.448 25.977C324.105 26.5536 325.224 27.0066 326.805 27.3361L333.055 28.5716C336.401 29.2511 338.895 30.3528 340.537 31.8766C342.179 33.4004 343 35.4082 343 37.8998C343 40.1856 342.343 42.183 341.029 43.8921C339.716 45.5807 337.909 46.9089 335.61 47.8767C333.311 48.824 330.674 49.2976 327.697 49.2976C323.141 49.2976 319.507 48.34 316.798 46.425C314.109 44.5099 312.539 41.9153 312.087 38.6411L321.416 38.1469C321.724 39.5472 322.422 40.6077 323.51 41.3284C324.598 42.0491 325.994 42.4095 327.697 42.4095C329.36 42.4095 330.705 42.08 331.731 41.4211C332.778 40.7621 333.301 39.9281 333.301 38.9191C333.301 37.21 331.823 36.0568 328.867 35.4597L322.925 34.2241C319.579 33.5446 317.085 32.3811 315.443 30.7338C313.801 29.0864 312.98 26.9757 312.98 24.4017C312.98 22.1777 313.585 20.2627 314.796 18.6565C316.008 17.0503 317.701 15.8148 319.877 14.9499C322.073 14.085 324.639 13.6526 327.574 13.6526C331.926 13.6526 335.344 14.5792 337.827 16.4325C340.331 18.2652 341.789 20.7672 342.199 23.9384Z" fill={colors.primary}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.5938C0 9.3767 2.50834 6.74434 5.65774 6.65643L25.6903 6.09717L45.7228 6.65643C48.8722 6.74434 51.3806 9.3767 51.3806 12.5938V43.1578C51.3806 46.3749 48.8722 49.0073 45.7228 49.0952L30.1222 49.5307C30.3641 51.2104 31.2268 52.7406 32.5412 53.7981C32.7958 54.0029 32.9611 54.308 32.9611 54.639V55.0991C32.9611 55.6459 32.527 56.0891 31.9917 56.0891H19.3889C18.8535 56.0891 18.4194 55.6459 18.4194 55.0991V54.639C18.4194 54.308 18.5848 54.0029 18.8393 53.7981C20.1537 52.7406 21.0164 51.2104 21.2583 49.5307L5.65774 49.0952C2.50834 49.0073 0 46.3749 0 43.1578V12.5938ZM15.1195 16.9044L38.2019 27.2164C38.9764 27.5624 38.9764 28.6843 38.2019 29.0303L15.1195 39.3423C14.6428 39.5552 14.2172 38.9561 14.5634 38.5594L19.0735 33.3931C21.6952 30.3899 21.6952 25.8567 19.0735 22.8535L14.5634 17.6872C14.2172 17.2906 14.6428 16.6914 15.1195 16.9044Z" fill={colors.primary}/>
    </svg>

);

export const MenuIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
    </svg>
);

export const IconSearch = () => (
    <svg
        width="19"
        height="24"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.32129 15.5C12.0492 15.5 15.0713 12.4779 15.0713 8.75C15.0713 5.02208 12.0492 2 8.32129 2C4.59337 2 1.57129 5.02208 1.57129 8.75C1.57129 12.4779 4.59337 15.5 8.32129 15.5Z"
            stroke={colors.secondary}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.5713 17L13.5713 14"
            stroke={colors.secondary}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const EventialsLogoWhite = () => (
    <svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.58576C0 3.36865 2.50834 0.736281 5.65774 0.648369L25.6903 0.0891113L45.7228 0.648369C48.8722 0.736281 51.3806 3.36865 51.3806 6.58576V37.1498C51.3806 40.3669 48.8722 42.9992 45.7228 43.0872L30.1222 43.5227C30.3641 45.2024 31.2268 46.7325 32.5412 47.79C32.7958 47.9949 32.9611 48.2999 32.9611 48.6309V49.0911C32.9611 49.6378 32.527 50.081 31.9917 50.081H19.3889C18.8535 50.081 18.4194 49.6378 18.4194 49.0911V48.6309C18.4194 48.2999 18.5848 47.9949 18.8393 47.79C20.1537 46.7325 21.0164 45.2024 21.2583 43.5227L5.65774 43.0872C2.50834 42.9992 0 40.3669 0 37.1498V6.58576ZM15.1195 10.8963L38.2019 21.2083C38.9764 21.5543 38.9764 22.6762 38.2019 23.0222L15.1195 33.3342C14.6428 33.5472 14.2172 32.948 14.5634 32.5514L19.0735 27.3851C21.6952 24.3819 21.6952 19.8486 19.0735 16.8454L14.5634 11.6791C14.2172 11.2825 14.6428 10.6833 15.1195 10.8963Z" fill={colors.offWhite}/>
    </svg>

);

export const FacebookIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="512px"
        height="512px"
        viewBox="0 0 96.124 96.123"
    >
        <g>
            <path
                d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803   c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654   c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246   c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z"
                fill={colors.white}
            />
        </g>
    </svg>
);

export const InstagramIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="512px"
        height="512px"
        viewBox="0 0 169.063 169.063"
    >
        <g>
            <path
                d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752   c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407   c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752   c17.455,0,31.656,14.201,31.656,31.655V122.407z"
                fill={colors.white}
            />
            <path
                d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561   C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561   c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"
                fill={colors.white}
            />
            <path
                d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78   c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78   C135.661,29.421,132.821,28.251,129.921,28.251z"
                fill={colors.white}
            />
        </g>
    </svg>
);

export const TwitterIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="512px"
        height="512px"
        viewBox="0 0 512.002 512.002"
    >
        <g>
            <path
                d="M512.002,97.211c-18.84,8.354-39.082,14.001-60.33,16.54c21.686-13,38.342-33.585,46.186-58.115   c-20.299,12.039-42.777,20.78-66.705,25.49c-19.16-20.415-46.461-33.17-76.674-33.17c-58.011,0-105.042,47.029-105.042,105.039   c0,8.233,0.929,16.25,2.72,23.939c-87.3-4.382-164.701-46.2-216.509-109.753c-9.042,15.514-14.223,33.558-14.223,52.809   c0,36.444,18.544,68.596,46.73,87.433c-17.219-0.546-33.416-5.271-47.577-13.139c-0.01,0.438-0.01,0.878-0.01,1.321   c0,50.894,36.209,93.348,84.261,103c-8.813,2.399-18.094,3.687-27.674,3.687c-6.769,0-13.349-0.66-19.764-1.888   c13.368,41.73,52.16,72.104,98.126,72.949c-35.95,28.176-81.243,44.967-130.458,44.967c-8.479,0-16.84-0.496-25.058-1.471   c46.486,29.807,101.701,47.197,161.021,47.197c193.211,0,298.868-160.062,298.868-298.872c0-4.554-0.104-9.084-0.305-13.59   C480.111,136.775,497.92,118.275,512.002,97.211z"
                fill={colors.white}
            />
        </g>
    </svg>
);

export const LinkedinIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 310 310"
        width="512px"
        height="512px"
    >
        <g id="XMLID_801_">
            <path
                id="XMLID_802_"
                d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73   C77.16,101.969,74.922,99.73,72.16,99.73z"
                fill={colors.white}
            />
            <path
                id="XMLID_803_"
                d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4   c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
                fill={colors.white}
            />
            <path
                id="XMLID_804_"
                d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599   c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319   c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995   C310,145.43,300.549,94.761,230.454,94.761z"
                fill={colors.white}
            />
        </g>
    </svg>
);

export const IconInputSearch = () => (
    <svg
        width="19"
        height="24"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.32129 15.5C12.0492 15.5 15.0713 12.4779 15.0713 8.75C15.0713 5.02208 12.0492 2 8.32129 2C4.59337 2 1.57129 5.02208 1.57129 8.75C1.57129 12.4779 4.59337 15.5 8.32129 15.5Z"
            stroke={colors.secondary}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.5713 17L13.5713 14"
            stroke={colors.secondary}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const TimeIconBlue = () => (
    <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 7.99984H6.66667V6.66651C6.66667 6.29784 6.368 5.99984 6 5.99984C5.632 5.99984 5.33333 6.29784 5.33333 6.66651V8.66651C5.33333 9.03517 5.632 9.33318 6 9.33318H8C8.368 9.33318 8.6667 9.03517 8.6667 8.66651C8.6667 8.29784 8.368 7.99984 8 7.99984ZM6 13.1665C3.51867 13.1665 1.5 11.1479 1.5 8.66651C1.5 6.18517 3.51867 4.16651 6 4.16651C8.4813 4.16651 10.5 6.18517 10.5 8.66651C10.5 11.1479 8.4813 13.1665 6 13.1665ZM6.65867 2.70517C6.66 2.69117 6.66667 2.68051 6.66667 2.6665V1.99984H7.33333C7.70133 1.99984 8 1.70184 8 1.33317C8 0.964504 7.70133 0.666504 7.33333 0.666504H4.66667C4.29867 0.666504 4 0.964504 4 1.33317C4 1.70184 4.29867 1.99984 4.66667 1.99984H5.33333V2.6665C5.33333 2.68051 5.34 2.69117 5.34133 2.70517C2.342 3.03451 0 5.58118 0 8.6665C0 11.9752 2.692 14.6665 6 14.6665C9.308 14.6665 12 11.9752 12 8.6665C12 5.58118 9.658 3.03451 6.65867 2.70517Z"
            fill={colors.blue}
        />
    </svg>
);

export const TimeIconRed = () => (
    <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 7.99984H6.66667V6.66651C6.66667 6.29784 6.368 5.99984 6 5.99984C5.632 5.99984 5.33333 6.29784 5.33333 6.66651V8.66651C5.33333 9.03517 5.632 9.33318 6 9.33318H8C8.368 9.33318 8.6667 9.03517 8.6667 8.66651C8.6667 8.29784 8.368 7.99984 8 7.99984ZM6 13.1665C3.51867 13.1665 1.5 11.1479 1.5 8.66651C1.5 6.18517 3.51867 4.16651 6 4.16651C8.4813 4.16651 10.5 6.18517 10.5 8.66651C10.5 11.1479 8.4813 13.1665 6 13.1665ZM6.65867 2.70517C6.66 2.69117 6.66667 2.68051 6.66667 2.6665V1.99984H7.33333C7.70133 1.99984 8 1.70184 8 1.33317C8 0.964504 7.70133 0.666504 7.33333 0.666504H4.66667C4.29867 0.666504 4 0.964504 4 1.33317C4 1.70184 4.29867 1.99984 4.66667 1.99984H5.33333V2.6665C5.33333 2.68051 5.34 2.69117 5.34133 2.70517C2.342 3.03451 0 5.58118 0 8.6665C0 11.9752 2.692 14.6665 6 14.6665C9.308 14.6665 12 11.9752 12 8.6665C12 5.58118 9.658 3.03451 6.65867 2.70517Z"
            fill={colors.primary}
        />
    </svg>
);

export const ProfileDefault = () => (
    <svg
        width="151"
        height="150"
        viewBox="0 0 151 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.5" width="150" height="150" rx="8" fill={colors.border}/>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.4998 93.6204C67.7676 93.6204 60.441 95.3472 55.427 96.9032C52.5569 97.7939 50.4478 100.407 49.9317 103.742L49.3925 107.227C49.1158 109.015 47.45 110.239 45.6717 109.96C43.8935 109.682 42.6761 108.008 42.9528 106.22L43.492 102.735C44.3352 97.2862 47.9245 92.3748 53.5046 90.6431C58.8626 88.9804 66.8773 87.0688 75.4998 87.0688C84.1223 87.0688 92.1369 88.9804 97.4949 90.6431C103.075 92.3748 106.664 97.2862 107.508 102.735L108.047 106.22C108.323 108.008 107.106 109.682 105.328 109.96C103.55 110.239 101.884 109.015 101.607 107.227L101.068 103.742C100.552 100.407 98.4427 97.7939 95.5726 96.9032C90.5585 95.3472 83.2319 93.6204 75.4998 93.6204Z"
            fill={colors.offWhite}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.4998 46.4368C68.3899 46.4368 62.6262 52.2005 62.6262 59.3103C62.6262 66.4202 68.3899 72.1839 75.4998 72.1839C82.6097 72.1839 88.3734 66.4202 88.3734 59.3103C88.3734 52.2005 82.6097 46.4368 75.4998 46.4368ZM56.1895 59.3103C56.1895 48.6455 64.835 40 75.4998 40C86.1646 40 94.8101 48.6455 94.8101 59.3103C94.8101 69.9752 86.1646 78.6207 75.4998 78.6207C64.835 78.6207 56.1895 69.9752 56.1895 59.3103Z"
            fill={colors.offWhite}
        />
    </svg>
);

export const ProfileCard = () => (
    <svg
        width="151"
        height="150"
        viewBox="0 0 151 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.5" width="150" height="150" rx="8" fill={colors.border}/>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.4998 93.6204C67.7676 93.6204 60.441 95.3472 55.427 96.9032C52.5569 97.7939 50.4478 100.407 49.9317 103.742L49.3925 107.227C49.1158 109.015 47.45 110.239 45.6717 109.96C43.8935 109.682 42.6761 108.008 42.9528 106.22L43.492 102.735C44.3352 97.2862 47.9245 92.3748 53.5046 90.6431C58.8626 88.9804 66.8773 87.0688 75.4998 87.0688C84.1223 87.0688 92.1369 88.9804 97.4949 90.6431C103.075 92.3748 106.664 97.2862 107.508 102.735L108.047 106.22C108.323 108.008 107.106 109.682 105.328 109.96C103.55 110.239 101.884 109.015 101.607 107.227L101.068 103.742C100.552 100.407 98.4427 97.7939 95.5726 96.9032C90.5585 95.3472 83.2319 93.6204 75.4998 93.6204Z"
            fill={colors.offWhite}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.4998 46.4368C68.3899 46.4368 62.6262 52.2005 62.6262 59.3103C62.6262 66.4202 68.3899 72.1839 75.4998 72.1839C82.6097 72.1839 88.3734 66.4202 88.3734 59.3103C88.3734 52.2005 82.6097 46.4368 75.4998 46.4368ZM56.1895 59.3103C56.1895 48.6455 64.835 40 75.4998 40C86.1646 40 94.8101 48.6455 94.8101 59.3103C94.8101 69.9752 86.1646 78.6207 75.4998 78.6207C64.835 78.6207 56.1895 69.9752 56.1895 59.3103Z"
            fill={colors.offWhite}
        />
    </svg>
);
