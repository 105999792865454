import styled from "styled-components";

const ContainerCard = styled.div`
    display: relative;

    width: 288px;
    height: 288px;
    background-color: ${(props) => props.theme.colors.white};
    margin-bottom: 70px;
    margin-right: 38px;

    .container-cover {
        position: relative;

        img {
            width: 288px;
            height: 200px;
            object-fit: cover;
            object-position: center;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, #ffffff00 0%, #a0a3bd99 100%);
            opacity: 0;
            &:hover {
                opacity: 1;
                transition: 0.5s;
            }
        }
    }

    .duration {
        position: relative;
        top: -30px;
        right: 10px;

        width: 100%;
        height: 20px;

        display: flex;
        justify-content: flex-end;

        span {
            padding: 0 4px;

            font-size: 10px;
            color: ${(props) => props.theme.colors.offWhite};
            background-color: ${(props) => props.theme.colors.body};
            border-radius: 4px;
        }
    }

    .card-title {
        display: block;

        color: ${(props) => props.theme.colors.title};
        margin-bottom: 6px;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        text-overflow: ellipsis;
    }

    .card-date {
        height: 15px;
        margin-bottom: 16px;

        display: inline-flex;
        align-items: center;

        color: ${(props) => props.theme.colors.label};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;

        span {
            margin-left: 8px;
        }
    }

    .btn-category {
        width: fit-content;
        padding: 6px 11px;
        background-color: ${(props) => props.theme.colors.inputBackground};
        border-radius: 30px;

        color: ${(props) => props.theme.colors.placeholder};
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: center;
    }
`;

export default ContainerCard;
