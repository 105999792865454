import styled from "styled-components";

const ContainerCaroussel2 = styled.div`
    display: relative;
    margin-top: 60px;

    height: fit-content;
    width: auto;

    .clickable-element {
        cursor: pointer;
    }

    .header-sub-title {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 64px;

        .container-arrow {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100px;

            .arrow-active {
                width: 40px;
                height: 40px;
                border: 2px solid ${(props) => props.theme.colors.title};
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .arrow-disabled {
                width: 40px;
                height: 40px;
                border: 2px solid ${(props) => props.theme.colors.offWhite};
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .card-list-area {
        overflow-x: hidden;
    }
    .card-list {
        display: flex;
    }

    @media screen and (max-width: 768px) {
        .header-sub-title.container-arrow {
            width: 80px;
        }
        .header-sub-title.container-arrow.arrow {
            width: 25px;
            height: 25px;
        }
    }
`;

export default ContainerCaroussel2;
