import styled from "styled-components";

interface IButtonCircleProps {
    active: boolean;
}

const ButtonCircle = styled.button<IButtonCircleProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 42px;
    height: 42px;
    border-radius: 40px;

    color: ${(props) => (props.active ? props.theme.colors.offWhite : props.theme.colors.body)};
    background-color: ${(props) => (props.active ? props.theme.colors.secondary : "transparent")};

    border: 1px solid ${(props) => props.theme.colors.secondary};
    outline: none;

    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    text-align: center;
    letter-spacing: 0.75px;

    margin: 0 5px;

    cursor: pointer;
`;

export default ButtonCircle;
