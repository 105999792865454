import React, { useContext, useEffect, useState } from "react";
import { Talk } from "../App";
import CardsCaroussel from "./Cards/CardsCaroussel";
import { DefaultContext } from "../contexts/DefaultContext";

const Suggestions = () => {
    const [talks, setTalks] = useState<Talk[]>([]);
    const [count, setCount] = useState<number>(0);

    const { suggestions, suggestionsCount } = useContext(DefaultContext);

    useEffect(() => {
        setTalks(suggestions);
        setCount(suggestionsCount);
    }, [suggestions, suggestionsCount]);

    return (
        <CardsCaroussel
            title="Sugestões pra você"
            description="Achamos que você pode curtir essas transmissões."
            talks={talks}
            count={count}
        />
    );
};

export default Suggestions;
