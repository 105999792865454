/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "styled-components";
import LogoEventialsLogo from "../images/LogoTipografico_Color.svg";
import { LOGIN_URL } from "../../settings";
import { useHistory } from "react-router-dom";

const ContainerHeader = styled.div`
    position: relative;
    top: 0;
    left: 0;

    span {
        font-size: 15px;
        color: ${(props) => props.theme.colors.label};

      &:hover{
        color: ${(props) => props.theme.colors.body};
      }
    }

    .header {
        height: 54px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 80px;
    }

    .header-menu {
        display: flex;
        align-items: center;

        a {
            margin-right: 20px;
        }

        img {
            width: 130px;
        }
    }

    .logo {
        display: flex;
        cursor: pointer;
    }
    .user-container {
        display: flex;
        align-items: center;

        a {
            margin-left: 20px;
        }
    }
    .hrx {
        position: absolute;
        top: 54px;
        width: 100%;
        height: 0.8px;
        margin: 0px;
        background-color: ${(props) => props.theme.colors.border};
    }

    @media screen and (max-width: 450px) {
        .header {
            height: 75px;
            margin: 0px;
        }
        .header-menu {
            width: 100%;
            margin: 0 5px;
        }
        .user-container {
            width: 100%;
            display: flex;
            justify-content: right;

            span {
                margin: 0 10px;
            }

            a{
              margin-left: 0;
            }
        }
        .hrx {
            position: absolute;
            top: 75px;
        }
    }

    @media screen and (max-width: 600px) {
        .explorer-menu {
            display: none;
        }
    }

    @media screen and (max-width: 320px) {
        span {
            font-size: 13px;
        }
    }
`;

const DefaultHeader = () => {
    const history = useHistory();

    return (
        <>
            <ContainerHeader>
                <div className="header">
                    <div className="header-menu">
                        <a onClick={(e) => {
                            e.preventDefault();
                            history.push("/");
                        }}>
                            <img src={LogoEventialsLogo} alt="Eventials" />
                        </a>
                        <a className="explorer-menu" onClick={(e) => {
                             e.preventDefault();
                             history.push("/");
                        }}>
                            <span>Explorar</span>
                        </a>
                    </div>
                    <div className="user-container">
                        <a href={LOGIN_URL + window.location.origin}>
                            <span>Criar Conta</span>
                        </a>
                        <a href={LOGIN_URL + window.location.origin}>
                            <span>Entrar</span>
                        </a>
                    </div>
                </div>
                <div className="hrx"></div>
            </ContainerHeader>
        </>
    );
};

export default DefaultHeader;
