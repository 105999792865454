import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchCourses from "../components/Search/SearchCourses";
import SearchEvents from "../components/Search/SearchEvents";
import SearchGroups from "../components/Search/SearchGroups";
import SearchProfiles from "../components/Search/SearchProfiles";
import SearchTalks from "../components/Search/SearchTalks";
import { Talk } from "../App";
import { Profile } from "../components/Search/SearchProfiles";
import { Group } from "../components/Search/SearchGroups";
import { Course } from "../components/Search/SearchCourses";
import { Event } from "../components/Search/SearchEvents";

const Container = styled.div`
    padding: 0 70px;
    @media screen and (max-width: 1200px) {
        padding: 0 40px;
    }
    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }
`;

const SearchInput = ({ searchText }: { searchText: string }) => {
    const [currentTalks, setCurrentTalks] = useState<Talk[]>([]);
    const [currentTalksCount, setCurrentTalksCount] = useState<number>(0);

    const [currentProfiles, setCurrentProfiles] = useState<Profile[]>([]);
    const [currentProfilesCount, setCurrentProfilesCount] = useState<number>(0);

    const [currentGroups, setCurrentGroups] = useState<Group[]>([]);
    const [currentGroupsCount, setCurrentGroupsCount] = useState<number>(0);

    const [currentCourses, setCurrentCourses] = useState<Course[]>([]);
    const [currentCoursesCount, setCurrentCoursesCount] = useState<number>(0);

    const [currentEvents, setCurrentEvents] = useState<Event[]>([]);
    const [currentEventsCount, setCurrentEventsCount] = useState<number>(0);

    useEffect(() => {
        if (searchText !== "") {
            handleSearch(searchText);
        }
    }, [searchText]);

    const handleSearch = (search: string) => {
        try {
            axios.get(`/?search=${search}`).then((res) => {
                setCurrentTalks(res.data.talks);
                setCurrentTalksCount(res.data.talks_count);

                setCurrentProfiles(res.data.profiles);
                setCurrentProfilesCount(res.data.profiles_count);

                setCurrentGroups(res.data.groups);
                setCurrentGroupsCount(res.data.groups_count);

                setCurrentCourses(res.data.courses);
                setCurrentCoursesCount(res.data.courses_count);

                setCurrentEvents(res.data.events);
                setCurrentEventsCount(res.data.events_count);
            });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <Container>
                <SearchTalks
                    searchText={searchText}
                    currentTalks={currentTalks}
                    currentTalksCount={currentTalksCount}
                />
                <SearchProfiles
                    searchText={searchText}
                    currentProfiles={currentProfiles}
                    countProfiles={currentProfilesCount}
                />
                <SearchGroups
                    searchText={searchText}
                    currentGroups={currentGroups}
                    countGroups={currentGroupsCount}
                />
                <SearchCourses
                    searchText={searchText}
                    currentCourses={currentCourses}
                    countCourses={currentCoursesCount}
                />
                <SearchEvents
                    searchText={searchText}
                    currentEvents={currentEvents}
                    countEvents={currentEventsCount}
                />
            </Container>
        </div>
    );
};

export default SearchInput;
