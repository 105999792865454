import React from "react";
import { Talk } from "../../App";
import { TimeIconBlue, TimeIconRed } from "../SVG";
import ContainerCard from "./StyleContainerCard";

const Card = ({ talk }: { talk: Talk }) => (
    <ContainerCard>
        <div className="container-card">
            <a
                href={talk.short_url}
                className="clickable-element cover-container"
            >
                {talk.cover && (
                    <div className="container-cover">
                        <div className="overlay" />
                        <img className="cover" src={talk.cover} alt="" />
                    </div>
                )}
                <div className="duration">
                    <span>{talk.real_duration}</span>
                </div>
            </a>
            <a href={talk.short_url} className="card-title clickable-element">
                {talk.title}
            </a>
            <p className="card-date">
                {talk.state === "recorded" ? <TimeIconBlue /> : <TimeIconRed />}

                <span>{talk.start_time}</span>
            </p>
            <div className="btn-category">{talk.category.name_pt_br}</div>
        </div>
    </ContainerCard>
);

export default Card;
