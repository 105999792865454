import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DefaultContext } from "../contexts/DefaultContext";
import { isMobile } from "../utils/utils";
import GrayButton from "./Buttons/GrayButton";

const ContainerCategories = styled.div`
  display: relative;
  margin-top: 60px;

  .sub-title {
    height: 30px;
    margin-bottom: 13px;
  }

  .description {
    margin-bottom: 64px;
  }

  .categories {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    justify-content: center;

    .category {
      margin: 20px 0;
      cursor: pointer;

      img {
        width: fit-content;
        height: fit-content;
      }
    }
  }

  @media screen and (max-width: 3100px) {
    .categories {
      grid-template-columns: repeat(7, 1fr);
    }
  }

  @media screen and (max-width: 2560px) {
    .categories {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  @media screen and (max-width: 1860px) {
    .categories {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  @media screen and (max-width: 1440px) {
    .categories {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media screen and (max-width: 980px) {
    .categories {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media screen and (max-width: 768px) {
    .categories {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 480px) {
    .categories {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
`;

interface Category {
  id: number;
  name: string;
  name_pt_br: string;
  slug: string;
  url: string;
  cover: any;
}

const Categories = () => {
  const [visibleCategories, setVisibleCategories] = useState<Category[]>([]);
  const { categories } = useContext(DefaultContext);
  const history = useHistory();

  useEffect(() => {
    setCategoriesOnMobile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const setCategoriesOnMobile = () => {
    if (isMobile()) {
      setVisibleCategories(categories.slice(0, 3));
    } else {
      setVisibleCategories(categories);
    }
  }

  window.addEventListener("resize", () => {
    setCategoriesOnMobile();
  });


  const showMore = () => {
    setVisibleCategories(categories);
  }

  return (
    <ContainerCategories>
      <div>
        <div className="sub-title">Categorias</div>
        <div className="categories">
          {visibleCategories.map(({ slug, name, cover }: Category) => (
            <div className="category" key={name}>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/category/${slug}`);
                  window.scrollTo(0, 0);
                }}
              >
                <img src={cover} alt={name} />
              </span>
            </div>
          ))}
          {visibleCategories.length < categories.length && (
            <GrayButton
              active={true}
              onClick={() => showMore()}
            >
              Mostrar mais
            </GrayButton>
          )}
        </div>
      </div>
    </ContainerCategories>
  );
};

export default Categories;
