export type TypesMediaQuerie = {
  maxWidth: {
    xs: string,
    sm: string,
    md: string,
    lg: string,
    xl: string,
    custom: (valor: string) => string,
  },
  minWidth: {
    xs: string,
    sm: string,
    md: string,
    lg: string,
    xl: string,
    custom: (valor: string) => string,
  },
};

export enum breakpointSize {
  Xs = 0,
  Sm = 600,
  Md = 960,
  Lg = 1366,
  Xl = 1920,
}

const mediaQuerie: TypesMediaQuerie = {
  maxWidth: {
    /* If screen size is less equal  */
    xs: `@media screen and (max-width: ${breakpointSize.Xs}px)`,
    sm: `@media screen and (max-width: ${breakpointSize.Sm}px)`,
    md: `@media screen and (max-width: ${breakpointSize.Md}px)`,
    lg: `@media screen and (max-width: ${breakpointSize.Lg}px)`,
    xl: `@media screen and (max-width: ${breakpointSize.Xl}px)`,
    custom: (valor: string) => `@media screen and (max-width: ${valor})`,
  },
  minWidth: {
    /* If screen size is more than */
    xs: `@media screen and (min-width: ${breakpointSize.Xs}px)`,
    sm: `@media screen and (min-width: ${breakpointSize.Sm}px)`,
    md: `@media screen and (min-width: ${breakpointSize.Md}px)`,
    lg: `@media screen and (min-width: ${breakpointSize.Lg}px)`,
    xl: `@media screen and (min-width: ${breakpointSize.Xl}px)`,
    custom: (valor: string) => `@media screen and (min-width: ${valor})`,
  },
};

export default mediaQuerie;
