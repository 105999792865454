import { Talk } from "../../App";
import CardsCaroussel from "../Cards/CardsCaroussel";

const SearchTalks = ({
    currentTalks,
    currentTalksCount,
    searchText
}: {
    currentTalks: Talk[];
    currentTalksCount: number;
    searchText: string;
}) => {
    return (
        <CardsCaroussel
            title="Transmissões"
            talks={currentTalks}
            count={currentTalksCount}
            searchText={searchText}
        />
    );
};

export default SearchTalks;
