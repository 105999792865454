function getEnv() {
    if (window.location.hostname === "highlights.eventials.com") {
        return "production";
    } else if (
        window.location.hostname.includes("stg") ||
        window.location.hostname.includes("staging")
    ) {
        return "staging";
    } else if (
        window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("127.0.0.1")
    ) {
        return "local";
    } else {
        return "production";
    }
}

function getAPIUrl(ENV) {
    switch (ENV.toLowerCase()) {
        case "production":
            return "https://highlights-api.eventials.com";
        case "staging":
            return "https://highlights-stg.eventials.com";
        case "local":
            return "https://highlights-stg.eventials.com";
        default:
            return "https://highlights-api.eventials.com";
    }
}

function getLoginURL(ENV) {
    switch (ENV.toLowerCase()) {
        case "production":
            return "https://eventials.com/login/?next=";
        case "staging":
            return "https://staging.eventials.com/login/?next=";
        case "local":
            return "https://local.eventials.com/login/?next=";
        default:
            return "https://eventials.com/login/?next=";
    }
}

function getAmbienteURL(ENV) {
    switch (ENV.toLowerCase()) {
        case "production":
            return "https://www.eventials.com";
        case "staging":
            return "https://staging.eventials.com";
        case "local":
            // return "https://local.eventials.com";
            return "https://staging.eventials.com";
        default:
            return "https://www.eventials.com";
    }
}

const ENV = getEnv();
const API_URL = getAPIUrl(ENV);
const LOGIN_URL = getLoginURL(ENV);
const AMBIENTE_URL = getAmbienteURL(ENV);

export { ENV, API_URL, LOGIN_URL, AMBIENTE_URL };
