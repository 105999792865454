import styled from "styled-components";

const ButtonReturnContainer = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 10px 15px;
    width: 116px;
    height: 44px;
    border-radius: 30px;

    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.body};
    outline: none;

    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;

    text-align: center;
    letter-spacing: 0.75px;

    margin: 0 10px;

    cursor: pointer;

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
            text-transform: none;
            margin-left: 15px;
        }
        .icon {
            transform: rotate(180deg);
            margin-bottom: 5px;
        }
    }

    @media screen and (max-width: 450px) {
        width: 40px;
        margin: 0 25px;
        .text {
            display: none;
        }
    }
`;

export default ButtonReturnContainer;
