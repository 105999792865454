import React from "react";
import { Profile } from "../Search/SearchProfiles";
import ContainerCard2 from "./StyleCard2";

const CardProfile = ({ profile }: { profile: Profile }) => (
    <ContainerCard2>
        <a href={profile.url} className="clickable-element cover-container">
            {profile.avatar && (
                <div
                    className="container-cover"
                    style={{ marginBottom: "15px" }}
                >
                    <div className="overlay"></div>
                    <div className="cover">
                        <img src={profile.avatar} alt={profile.user.username} />
                    </div>
                </div>
            )}
        </a>

        <a href={profile.url} className="clickable-element text-container">
            <div className="title">
                <p>
                    {profile.user.first_name} {profile.user.last_name}
                </p>
            </div>
            <div className="sub-title">{profile.user.username}</div>
        </a>
    </ContainerCard2>
);

export default CardProfile;
