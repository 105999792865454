import styled from "styled-components";

const ContainerCard2 = styled.div`
    display: relative;

    width: 180px;
    height: auto-fit-content;
    background-color: ${(props) => props.theme.colors.white};
    margin: 0 25px 20px;

    .cover-container {
        width: 150px;
        height: 150px;
        position: relative;
        margin-bottom: 10px;
    }

    .text-container {
        position: relative;
        width: 160px;
        height: 30px;
    }

    .container-cover {
        position: relative;

        .cover {
            width: 150px;
            height: 150px;

            margin: auto;
        }

        img {
            width: 150px;
            height: 150px;
            border-radius: 8px;
            object-fit: cover;
            object-position: center;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            width: 150px;
            margin: auto;

            background: linear-gradient(180deg, #ffffff00 0%, #a0a3bd99 100%);
            opacity: 0;
            &:hover {
                border-radius: 8px;
                opacity: 1;
                transition: 0.5s;
            }
        }
    }

    .title {
        height: 40px;
        width: 170px;
        display: block;

        color: ${(props) => props.theme.colors.title};
        margin-bottom: 6px;
        margin-top: 15px;

        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: center;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .sub-title {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        color: ${(props) => props.theme.colors.title};
        margin-bottom: 6px;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export default ContainerCard2;
