type TpographyBody = {
  fontWeight: number,
  fontSize: string,
  letterSpacing: number,
  lineHeight: string,
};

export type TypographyType = {
  heading1: TpographyBody,
  heading2: TpographyBody,
  heading3: TpographyBody,
  heading4: TpographyBody,
  heading5: TpographyBody,
  heading6: TpographyBody,
  subheading1: TpographyBody,
  body1: TpographyBody,
  body2: TpographyBody,
  body3: TpographyBody,
  body4: TpographyBody,
  titleBold1: TpographyBody,
  titleBold2: TpographyBody,
  titleBold3: TpographyBody,
  titleNormal1: TpographyBody,
  titleNormal2: TpographyBody,
  titleNormal3: TpographyBody,
  button1: TpographyBody,
  button2: TpographyBody,
  button3: TpographyBody,
  button4: TpographyBody,
};

const typography: TypographyType = {
  heading1: {
    fontWeight: 700,
    fontSize: '120px',
    letterSpacing: 0,
    lineHeight: '126px',
  },
  heading2: {
    fontWeight: 700,
    fontSize: '64px',
    letterSpacing: 0,
    lineHeight: '70px',
  },
  heading3: {
    fontWeight: 700,
    fontSize: '48px',
    letterSpacing: 0,
    lineHeight: '54px',
  },
  heading4: {
    fontWeight: 700,
    fontSize: '36px',
    letterSpacing: 0,
    lineHeight: '42px',
  },
  heading5: {
    fontWeight: 700,
    fontSize: '24px',
    letterSpacing: 0,
    lineHeight: '30px',
  },
  heading6: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  subheading1: {
    fontWeight: 700,
    fontSize: '16px',
    letterSpacing: 1.5,
    lineHeight: '24px',
  },
  body1: {
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '26px',
  },
  body2: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  body3: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  body4: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  titleBold1: {
    fontWeight: 700,
    fontSize: '22px',
    letterSpacing: 0,
    lineHeight: '28px',
  },
  titleBold2: {
    fontWeight: 700,
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  titleBold3: {
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  titleNormal1: {
    fontWeight: 400,
    fontSize: '22px',
    letterSpacing: 0,
    lineHeight: '28px',
  },
  titleNormal2: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  titleNormal3: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  button1: {
    fontWeight: 500,
    fontSize: '22px',
    letterSpacing: 0.75,
    lineHeight: '28px',
  },
  button2: {
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: 0.75,
    lineHeight: '24px',
  },
  button3: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: 0.75,
    lineHeight: '20px',
  },
  button4: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: 0.75,
    lineHeight: '20px',
  },
};

export default typography;

export type TypographyTypes =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'subheading1'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'titleBold1'
  | 'titleBold2'
  | 'titleBold3'
  | 'titleNormal1'
  | 'titleNormal2'
  | 'titleNormal3'
  | 'button1'
  | 'button2'
  | 'button3'
  | 'button4';
