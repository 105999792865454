import colors, { TypeColors } from './colors';
import typography, { TypographyType } from './typography';
import mediaQuerie, { TypesMediaQuerie } from './mediaQuerie';

export type ThemeTypes = {
  colors: TypeColors,
  mediaQuerie: TypesMediaQuerie,
  typography: TypographyType,
};
export type ThemeColorsType = typeof colors;

const theme: ThemeTypes = {
  colors,
  mediaQuerie,
  typography,
};

export default theme;
