import axios from "axios";
import React, { useEffect, useState } from "react";
import CardEvent from "../Cards/CardEvent";
import ContainerCaroussel2 from "../Cards/StyleContainerCaroussel2";
import {
    ArrowLeft,
    ArrowLeftDisabled,
    ArrowRight,
    ArrowRightDisabled,
} from "../SVG";

export interface Event {
    name: string;
    slug: string;
    start_time: boolean;
    cover: string;
    is_public: boolean;
    is_paid: boolean;
    short_url: string;
    talk_count: number;
}

interface ISearchEvent {
    currentEvents: Event[];
    countEvents: number;
    searchText: string;
}

const SearchEvents = ({
    currentEvents,
    countEvents,
    searchText,
}: ISearchEvent) => {
    const [currentEvent, setCurrentEvent] = useState<Event[]>([]);
    const [scrollX, setScrollX] = useState<number>(0);
    const [numberOfScroll, setNumberOfScroll] = useState<number>(1);
    const [page, setPage] = useState<number>(2);

    const count = countEvents;
    const cardWidth = 326;
    const pageWidth = window.innerWidth;
    const [scrollWidth, setScrollWidth] = useState<number>(pageWidth);

    useEffect(() => {
        setCurrentEvent(currentEvents);
    }, [currentEvents]);

    const handleLeftArrow = () => {
        if (numberOfScroll >= 0) {
            let x = scrollX + cardWidth;
            if (x > 0) {
                x = 0;
            }
            setScrollX(x);
            setNumberOfScroll(numberOfScroll - 1);
        }
    };

    const handleRightArrow = () => {
        if (numberOfScroll < count) {
            setNumberOfScroll(numberOfScroll + 1);

            let x = numberOfScroll * cardWidth;
            setScrollX(-x);
            setScrollWidth(scrollWidth + cardWidth);

            if (
                numberOfScroll * cardWidth > pageWidth - 3 * cardWidth &&
                currentEvent.length % 12 === 0 &&
                scrollWidth > currentEvent.length * cardWidth
            ) {
                setPage(page + 1);

                try {
                    axios
                        .get(`/?search=${searchText}&p=${page}`)
                        .then((res) => {
                            const arr = [...currentEvent, ...res.data.events];
                            setCurrentEvent(arr);
                        });
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    const whenEventIsNotEmpty = () => {
        return currentEvents.length > 0;
    };

    return (
        <ContainerCaroussel2>
            <div className="container">
                <div>
                    <div className="header-sub-title">
                        <div className="sub-title">
                            <span>Eventos</span>
                        </div>
                        {whenEventIsNotEmpty() && (
                            <div className="container-arrow">
                                {numberOfScroll - 1 > 0 ? (
                                    <div
                                        className="arrow-active clickable-element"
                                        onClick={handleLeftArrow}
                                    >
                                        <ArrowLeft />
                                    </div>
                                ) : (
                                    <div className="arrow-disabled">
                                        <ArrowLeftDisabled />
                                    </div>
                                )}

                                {numberOfScroll > 0 &&
                                numberOfScroll < count ? (
                                    <div
                                        className="arrow-active clickable-element"
                                        onClick={handleRightArrow}
                                    >
                                        <ArrowRight />
                                    </div>
                                ) : (
                                    <div className="arrow-disabled">
                                        <ArrowRightDisabled />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {whenEventIsNotEmpty() ? (
                    <div className="card-list-area">
                        <div
                            className="card-list"
                            style={{
                                marginLeft: scrollX,
                                width: count * cardWidth,
                            }}
                        >
                            {currentEvents.map((event: Event) => (
                                <CardEvent event={event} />
                            ))}
                        </div>
                    </div>
                ) : (
                    <p
                        style={{
                            textAlign: "center",
                            padding: "50px",
                            fontWeight: "bold",
                        }}
                    >
                        Nenhum resultado encontrado :(
                    </p>
                )}
            </div>
        </ContainerCaroussel2>
    );
};

export default SearchEvents;
