import React, { useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DefaultContext } from "../../contexts/DefaultContext";
import { EventialsLogo, MenuIcon } from "../SVG";
import { AMBIENTE_URL } from "../../settings";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { isMobile } from "../../utils/utils";
import colors from "../../theme/colors";

interface IContainerDropDown {
  visible: string;
}

const ContainerHeader = styled.div`
  position: relative;
  top: 0;
  left: 0;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 80px;
  }

  .header-menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    z-index: 11;
    cursor: pointer;
    height: 50px;

    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    align-content: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .hry {
    width: 0.8px;
    height: 54px;
    background-color: ${(props) => props.theme.colors.border};
  }

  .hrx {
    position: absolute;
    top: 54px;
    width: 100%;
    height: 0.8px;
    margin: 0px;
    background-color: ${(props) => props.theme.colors.border};
  }

  .user-container {
    z-index: 11;
    display: flex;
    align-items: center;
    img {
      height: 32px;
      border: 1px solid ${(props) => props.theme.colors.border};
    }

    .user-dropdown {
      display: flex;
      align-items: center;
      margin-left: 5px;
      span {
        height: 15px;
        max-width: 104px;

        color: ${(props) => props.theme.colors.greyMedium};
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .arrow {
        height: 15px;
        width: 20px;
        margin: 0 0 3px 3px;

        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        width: 0;
        height: 0;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${(props) => props.theme.colors.greyMedium};
      }
    }
    .user-container:hover {
      .container-menu {
        visibility: visible;
      }
    }
  }

  @media screen and (max-width: 450px) {
    .header {
      margin: 0px;
    }
  }
`;

const ContainerDropdownMenu = styled.div<IContainerDropDown>`
    position: absolute;
    top: 0;
    left: 75px;
    z-index: 10;

    .container-menu {
        visibility: ${(props) => props.visible};
        width: 180px;
        height: auto;
        padding: 10px 0;
        margin: 0;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        background-color: white;
    }

    hr {
        border: none;
        border-bottom: solid 1px ${(props) => props.theme.colors.line};
        margin: 8px 0 13px;
    }

    .list-item {
        padding: 3px 15px;
        a {
            color: ${(props) => props.theme.colors.body};
        }
    }

    .list-item span .fonticon-coroa2:before {
        
    }
    .list-item a:hover {
            color: ${(props) => props.theme.colors.primary};
        }

    @media screen and (max-width: 450px) {
        left: 0px;
    }
`;

const ContainerDropdownUser = styled.div<IContainerDropDown>`
  position: absolute;
  top: 0;
  right: 65px;
  z-index: 10;

  .container-user {
    visibility: ${(props) => props.visible};
    width: 180px;
    height: auto;
    padding: 10px 0;
    margin: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    background-color: ${(props) => props.theme.colors.white};
  }

  hr {
    border: none;
    border-bottom: solid 1px ${(props) => props.theme.colors.line};
    margin: 8px 0 13px;
  }

  .list-item {
    padding: 3px 15px;

    a {
      color: ${(props) => props.theme.colors.body};
    }

    a span {
      color: ${(props) => props.theme.colors.greenWater};
      font-size: 17px;
      position: relative;
      top: 3px;
      margin-right: 3px;
    }
  }
  .list-item:hover {
    a {
      color: ${(props) => props.theme.colors.primary};
    }
    a span {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media screen and (max-width: 450px) {
    right: 5px;
  }
`;

const LoggedHeader = () => {
  const { username, avatar } = useContext(DefaultContext);

  const refMenu = useRef<HTMLDivElement>(null);

  const refUser = useRef<HTMLDivElement>(null);

  const [onMenuList, setOnMenuList] = useState<boolean>(false);
  const [onMenuUserList, setOnMenuUserList] = useState<boolean>(false);
  const [visibilityMenu, setVisibilityMenu] = useState<"visible" | "hidden">(
    "hidden"
  );
  const [visibilityUser, setVisibilityUser] = useState<"visible" | "hidden">(
    "hidden"
  );
  const history = useHistory();

  const hrefChannel = AMBIENTE_URL + "/" + username + "/";

  const handleCloseMenu = () => {
    if (onMenuList) {
      setVisibilityMenu("visible");
    } else {
      setVisibilityMenu("hidden");
    }
  };

  const handleVisibilityMenu = () => {
    setVisibilityMenu("visible");
    setOnMenuList(true);
  };

  const handleVisibilityUser = () => {
    setVisibilityUser("visible");
    setOnMenuUserList(true);
  };

  const handleCloseUser = () => {
    if (onMenuUserList) {
      setVisibilityUser("visible");
    } else {
      setVisibilityUser("hidden");
    }
  };

  const handleToggleMenu = () => {
    if (visibilityMenu === "visible") {
      setVisibilityMenu("hidden");
    } else {
      setVisibilityMenu("visible");
    }
  };

  const handleToggleUser = () => {
    if (visibilityUser === "visible") {
      setVisibilityUser("hidden");
    } else {
      setVisibilityUser("visible");
    }
  };

  useOutsideAlerter(refMenu, handleCloseMenu);

  useOutsideAlerter(refUser, handleCloseUser);

  return (
    <>
      <ContainerHeader>
        <div className="header">
          <div className="header-menu">
            <div style={{ position: "absolute", top: "15px" }} />
            <div
              className="logo"
              onClick={(e) => {
                e.preventDefault();
                history.push("/");
              }}
            >
              <EventialsLogo />
            </div>
            <div className="hry"></div>
            <div
              className="logo"
              onMouseOver={!isMobile() ? handleVisibilityMenu : undefined}
              onMouseLeave={!isMobile() ? handleCloseMenu : undefined}
              onClick={isMobile() ? handleToggleMenu : undefined}
              ref={refMenu}
            >
              <MenuIcon />
            </div>
          </div>
          <div
            className="user-container"
            onMouseOver={!isMobile() ? handleVisibilityUser : undefined}
            onMouseLeave={!isMobile() ? handleCloseUser : undefined}
            onClick={isMobile() ? handleToggleUser : undefined}
            onScrollCapture={(e) => console.log(e)}
            ref={refUser}
          >
            <img src={avatar} alt="user" />
            <div className="user-dropdown">
              <span>{username}</span>
              <div className="arrow"></div>
            </div>
          </div>
        </div>
        <div className="hrx"></div>
      </ContainerHeader>
      {visibilityMenu === "visible" && (
        <ContainerDropdownMenu
          visible={visibilityMenu}
          onMouseOver={() => setOnMenuList(true)}
          onMouseLeave={() => {
            setOnMenuList(false);
            setVisibilityMenu("hidden");
          }}
        >
          <div className="container-menu">
            <div style={{ width: "180px", height: "55px" }} />
            <div className="list-menu">
              <div className="list-item">
                <a href="/">Explorar</a>
              </div>
              <div className="list-item">
                <a href={`${AMBIENTE_URL}/dashboard/attending/`}>
                  Minhas Inscrições
                </a>
              </div>
              <div className="list-item">
                <hr />
              </div>
              <div className="list-item">
                <a
                  href="/"
                  style={{
                    color: colors.primary,
                    textTransform: "uppercase",
                    fontWeight: "600",
                  }}
                >
                  Meu Canal
                </a>
              </div>
              <div className="list-item">
                <a href={`${AMBIENTE_URL}/dashboard/talks/`}>Webinars</a>
              </div>
              <div className="list-item">
                <a href={`${AMBIENTE_URL}/dashboard/events/`}>Eventos</a>
              </div>
              <div className="list-item">
                <a href={`${AMBIENTE_URL}/dashboard/courses/`}>Cursos</a>
              </div>
              <div className="list-item">
                <a href={`${AMBIENTE_URL}/dashboard/groups/`}>Grupos</a>
              </div>
            </div>
          </div>
        </ContainerDropdownMenu>
      )}
      {visibilityUser === "visible" && (
        <ContainerDropdownUser
          visible={visibilityUser}
          onMouseOver={() => setOnMenuUserList(true)}
          onMouseLeave={() => {
            setOnMenuUserList(false);
            setVisibilityUser("hidden");
          }}
        >
          <div className="container-user">
            <div style={{ width: "100px", height: "55px" }} />
            <div className="list-menu">
              <div className="list-item">
                <a href={`${AMBIENTE_URL}/dashboard/`}>Painel</a>
              </div>
              <div className="list-item">
                <a href={hrefChannel}>Meu Canal</a>
              </div>
              <div className="list-item">
                <a href={`${AMBIENTE_URL}/reports/overview/`}>Relatórios</a>
              </div>
              <div className="list-item">
                <a href={`${AMBIENTE_URL}/settings/`}>Configurações</a>
              </div>
              <div
                className="list-item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "4px 40px 4px 20px",
                  margin: "5px 0",
                  borderTop: `1px solid ${colors.line}`,
                  borderBottom: `1px solid ${colors.line}`,
                  background: colors.background,
                }}
              >
                <a href={`${AMBIENTE_URL}/settings-plan/`}>
                  <span className="fonticon-coroa2"></span>
                  <strong
                    style={{
                      marginLeft: "3px",
                    }}
                  >
                    Meu Plano
                  </strong>
                </a>
              </div>
              <div className="list-item">
                <a
                  href={`${AMBIENTE_URL}/logout/?next=${window.location.href}`}
                >
                  Sair
                </a>
              </div>
            </div>
          </div>
        </ContainerDropdownUser>
      )}
    </>
  );
};

export default LoggedHeader;
