import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const DefaultContext = createContext<any>({
    banners: [],
    popularFromWeek: [],
    popularFromWeekCount: [],
    suggestions: [],
    suggestionsCount: [],
    categories: [],
    username: null,
    avatar: null,
});

const DefaultContextProvider: React.FC = ({ children }) => {
    const [banners, setBanners] = useState<any>([]);
    const [popularFromWeek, setPopularFromWeek] = useState<any>([]);
    const [popularFromWeekCount, setPopularFromWeekCount] = useState<number>(0);
    const [suggestions, setSuggestions] = useState<any>([]);
    const [suggestionsCount, setSuggestionsCount] = useState<number>(0);
    const [categories, setCategories] = useState<any>([]);
    const [username, setUsername] = useState<any>(null);
    const [avatar, setAvatar] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        try{
            axios.get("/home/", {withCredentials: true}).then((res) => {
                const banners = res.data.banners.map((slides:any) => {
                  return slides
                });
                setBanners(banners);

                const popularFromWeek = res.data.popular_talks;
                const popularFromWeekCount = res.data.popular_count;
                setPopularFromWeek(popularFromWeek);
                setPopularFromWeekCount(popularFromWeekCount);

                const suggestions = res.data.featured_talks;
                const suggestionsCount = res.data.featured_count;
                setSuggestions(suggestions);
                setSuggestionsCount(suggestionsCount);

                const categories = res.data.categories;
                setCategories(categories);

                const username = res.data.username;
                setUsername(username);

                const avatar = res.data.avatar;
                setAvatar(avatar);
                
                setLoading(false);
            });
        } catch (err) {
            console.log(err);
        }
    }, []);

    return (
        <DefaultContext.Provider
            value={{
                banners,
                popularFromWeek,
                popularFromWeekCount,
                suggestions,
                suggestionsCount,
                categories,
                username,
                avatar,
                loading,
            }}
        >
            {children}
        </DefaultContext.Provider>
    );
};

export default DefaultContextProvider;
