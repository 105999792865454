import styled from "styled-components";

const Container = styled.div`
padding: 0 70px;

@media screen and (max-width: 1200px) {
    padding: 0 40px;
}
@media screen and (max-width: 768px) {
    padding: 0 20px;
}
`;

export default Container;
