import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CardsGrid from "../Cards/CardsGrid";

const ContainerSearchCategory = styled.div`
    display: relative;
    margin-top: 60px;
`;

const SearchCategories = () => {
    const location = useLocation<any>();
    const category = location.pathname.replace("/category/", "");

    return (
        <ContainerSearchCategory>
            <CardsGrid category={category} />
        </ContainerSearchCategory>
    );
};

export default SearchCategories;
