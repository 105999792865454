import "./App.css";
import "antd/dist/antd.css";

import axios from "axios";
import { useContext, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Explorer from "./components/Explorer";
import Footer from "./components/Footer";
import LoggedHeader from "./components/header/LoggedHeader";
import Loading from "./components/Loading";
import { DefaultContext } from "./contexts/DefaultContext";
import Home from "./pages/Home";
import SearchCategory from "./pages/SearchCategory";
import SearchInput from "./pages/SearchInput";
import { API_URL } from "./settings";
import DefaultHeader from "./components/header/DefaultHeader";

axios.defaults.baseURL = API_URL;

export interface Talk {
    id: number;
    title: string;
    short_url: string;
    slug: string;
    state: string;
    cover?: string;
    start_time: string;
    real_end_time: string;
    real_duration: string;
    category_id: string;
    is_public: boolean;
    is_active: boolean;
    featured: number;
    category: Category;
}

export interface Category {
    name: string;
    name_pt_br: string;
    name_en: string;
    name_es: string;
    slug: string;
    slug_pt_br: string;
    slug_en: string;
    slug_es: string;
    id: number;
}

function App() {
    const { username, avatar, loading } = useContext(DefaultContext);

    const [searchText, setSearchText] = useState<string>("");

    const updateSearchValue = (search: string): void => {
        setSearchText(search);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <BrowserRouter>
                {avatar === null && username === null ? (
                    <DefaultHeader />
                ) : (
                    <LoggedHeader />
                )}
                <div style={{ maxWidth: "4000px", margin: "auto" }}>
                    <Explorer updateSearchValue={updateSearchValue} />
                    <Route exact path="/" component={() => <Home />} />
                    <Route
                        exact
                        path="/category/:category"
                        key="/"
                        component={() => <SearchCategory />}
                    />
                    <Route
                        exact
                        path="/search"
                        component={() => (
                            <SearchInput searchText={searchText} />
                        )}
                    />
                </div>
            </BrowserRouter>
            <Footer />
        </>
    );
}

export default App;
