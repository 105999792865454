import axios from "axios";
import React, { useEffect, useState } from "react";
import CardProfile from "../Cards/CardProfile";
import ContainerCaroussel2 from "../Cards/StyleContainerCaroussel2";
import {
    ArrowLeft,
    ArrowLeftDisabled,
    ArrowRight,
    ArrowRightDisabled,
} from "../SVG";

export interface Profile {
    avatar: string;
    id: number;
    url: string;
    user: User;
}

interface User {
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_active: boolean;
    is_public: boolean;
    id: number;
}

interface ISearchProfile {
    currentProfiles: Profile[];
    countProfiles: number;
    searchText: string;
}

const SearchProfiles = ({
    currentProfiles,
    countProfiles,
    searchText,
}: ISearchProfile) => {
    const [currentProfile, setCurrentProfile] = useState<Profile[]>([]);
    const [scrollX, setScrollX] = useState<number>(0);
    const [numberOfScroll, setNumberOfScroll] = useState<number>(1);
    const [page, setPage] = useState<number>(2);

    const count = countProfiles;
    const cardWidth = 230;
    const pageWidth = window.innerWidth;
    const [scrollWidth, setScrollWidth] = useState<number>(pageWidth);

    useEffect(() => {
        setCurrentProfile(currentProfiles);
    }, [currentProfiles]);

    const handleLeftArrow = () => {
        if (numberOfScroll >= 0) {
            let x = scrollX + cardWidth;
            if (x > 0) {
                x = 0;
            }
            setScrollX(x);
            setNumberOfScroll(numberOfScroll - 1);
        }
    };

    const handleRightArrow = () => {
        if (numberOfScroll < count) {
            setNumberOfScroll(numberOfScroll + 1);
            let x = numberOfScroll * cardWidth;
            setScrollX(-x);
            setScrollWidth(scrollWidth + cardWidth);

            if (
                numberOfScroll * cardWidth > pageWidth - 3 * cardWidth &&
                currentProfile.length % 12 === 0 &&
                scrollWidth > currentProfile.length * cardWidth
            ) {
                setPage(page + 1);

                try {
                    axios
                        .get(`/?search=${searchText}&p=${page}`)
                        .then((res) => {
                            const arr = [
                                ...currentProfile,
                                ...res.data.profiles,
                            ];
                            setCurrentProfile(arr);
                        });
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    const whenProfileIsNotEmpty = () => {
        return currentProfile.length > 0;
    };

    return (
        <ContainerCaroussel2>
            <div className="container">
                <div>
                    <div className="header-sub-title">
                        <div className="sub-title">
                            <span>Perfis</span>
                        </div>
                        {whenProfileIsNotEmpty() && (
                            <div className="container-arrow">
                                {numberOfScroll - 1 > 0 ? (
                                    <div
                                        className="arrow-active clickable-element"
                                        onClick={handleLeftArrow}
                                    >
                                        <ArrowLeft />
                                    </div>
                                ) : (
                                    <div className="arrow-disabled">
                                        <ArrowLeftDisabled />
                                    </div>
                                )}

                                {numberOfScroll > 0 &&
                                numberOfScroll < count ? (
                                    <div
                                        className="arrow-active clickable-element"
                                        onClick={handleRightArrow}
                                    >
                                        <ArrowRight />
                                    </div>
                                ) : (
                                    <div className="arrow-disabled">
                                        <ArrowRightDisabled />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {whenProfileIsNotEmpty() ? (
                    <div className="card-list-area">
                        <div
                            className="card-list"
                            style={{
                                marginLeft: scrollX,
                                width: count * cardWidth,
                            }}
                        >
                            {currentProfile.map((profile: Profile) => (
                                <CardProfile profile={profile} />
                            ))}
                        </div>
                    </div>
                ) : (
                    <p
                        style={{
                            textAlign: "center",
                            padding: "50px",
                            fontWeight: "bold",
                        }}
                    >
                        Nenhum resultado encontrado :(
                    </p>
                )}
            </div>
        </ContainerCaroussel2>
    );
};

export default SearchProfiles;
