export type TypeColorNames =
  | 'primary'
  | 'primaryDark'
  | 'primaryDark2'
  | 'primaryLight'
  | 'secondary'
  | 'terciary'
  | 'darkBackground'
  | 'inputBackground'
  | 'placeholder'
  | 'label'
  | 'body'
  | 'title'
  | 'background'
  | 'backgroundDark'
  | 'offWhite'
  | 'line'
  | 'error'
  | 'errorDark'
  | 'errorLight'
  | 'success'
  | 'successDark'
  | 'successLight'
  | 'white'
  | 'black'
  | 'transparent'
  | 'blue'
  | 'topbarBackground'
  | 'yellow'
  | 'greyDark'
  | 'topbarLabelColor'
  | 'border'
  | 'greyMedium'
  | 'greenWater'
  | 'greyFooter';

export type TypeColors = {
  // Brand UI
  primary: string,
  primaryDark: string,
  primaryDark2: string,
  primaryLight: string,

  secondary: string,
  terciary: string,
  darkBackground: string,

  // Theme
  inputBackground: string,
  placeholder: string,
  label: string,
  body: string,
  title: string,
  background: string,
  border: string,
  backgroundDark: string,
  offWhite: string,
  line: string,
  greyMedium: string,

  // Semantic
  error: string,
  errorDark: string,
  errorLight: string,
  success: string,
  successDark: string,
  successLight: string,
  white: string,
  black: string,
  transparent: string,
  blue: string,
  yellow: string,
  greyDark: string,
  topbarBackground: string,
  topbarLabelColor: string,
  greenWater: string,
  greyFooter: string,
};

const colors: TypeColors = {
  // Brand UI
  primary: '#FF3655',
  primaryDark: '#e5304c',
  primaryDark2: '#cc2b44',
  primaryLight: '#ffaebb',
  
  secondary: '#12121B',
  terciary: '#00AEEF',
  darkBackground: '#28323B',
  
  // Theme
  inputBackground: 'rgb(247, 247, 252)',
  placeholder: '#A0A3BD',
  label: '#6E7191',
  body: '#4E4B66',
  title: '#12121B',
  background: '#F7F7FC',
  border: '#D6D8E7',
  backgroundDark: '#222222',
  offWhite: '#FCFCFC',
  line: '#EFF0F6',
  greyMedium: '#6c6d6f',

  // Semantic
  error: '#EB1C1C',
  errorDark: '#C71515',
  errorLight: '#FFB8B8',
  success: '#00BA88',
  successDark: '#00966D',
  successLight: '#F3FDFA',
  greenWater: '#41e1c6',
  white: '#FFFFFF',
  black: '#000000',
  transparent: 'rgba(0,0,0,0)',
  blue: '#0020F9',
  yellow: '#FFAE09',
  greyDark: '#313D46',
  greyFooter: '#43464a',
  topbarBackground: '#fff',
  topbarLabelColor: '#fff',
};

export default colors;

export const randomColor = (): TypeColorNames => {
  const colorsArray = Object.keys(colors);
  let color = null;

  do {
    color = colorsArray[Math.floor(Math.random() * colorsArray.length)];
  } while (color === 'transparent' || color === 'white' || !color);

  return 'primary';
};
