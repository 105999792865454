import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Talk } from "../../App";
import {
    ArrowLeft,
    ArrowLeftDisabled,
    ArrowRight,
    ArrowRightDisabled,
} from "../SVG";
import Card from "./Card";

const ContainerCardsCaroussel = styled.div`
    display: relative;
    margin-top: 60px;

    height: fit-content;
    width: auto;

    .clickable-element {
        cursor: pointer;
    }

    .header-sub-title {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 13px;

        .container-arrow {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100px;

            .arrow-active {
                width: 40px;
                height: 40px;
                border: 2px solid ${(props) => props.theme.colors.secondary};
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .arrow-disabled {
                width: 40px;
                height: 40px;
                border: 2px solid ${(props) => props.theme.colors.line};
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .card-list-area {
        overflow-x: hidden;
    }
    .card-list {
        display: flex;
        scroll-behavior: smooth;
        transition: margin-left 1s;
        overflow-y: hidden;
    }

    @media screen and (max-width: 768px) {
        .header-sub-title.container-arrow {
            width: 80px;
        }
        .header-sub-title.container-arrow.arrow {
            width: 25px;
            height: 25px;
        }
    }
`;

interface ICardsCarousselProps {
    title: string;
    description?: string;
    talks: Talk[];
    count: number;
    searchText?: string;
}

const CardsCaroussel = ({
    title,
    description,
    talks,
    count,
    searchText,
}: ICardsCarousselProps) => {
    const [currentTalks, setCurrentTalks] = useState<Talk[]>([]);
    const [scrollX, setScrollX] = useState<number>(0);
    const [numberOfScroll, setNumberOfScroll] = useState<number>(1);
    const [page, setPage] = useState<number>(2);
    const [dragging, setDragging] = useState<any>({
        active: false,
        x: 0
    });

    const cardWidth = 326;
    const pageWidth = window.innerWidth;
    const [scrollWidth, setScrollWidth] = useState<number>(pageWidth);

    useEffect(() => {
        setCurrentTalks(talks);
    }, [talks]);

    const handleLeftArrow = () => {
        if (numberOfScroll >= 0) {
            let x = scrollX + cardWidth;
            if (x > 0) {
                x = 0;
            }
            setScrollX(x);
            setNumberOfScroll(numberOfScroll - 1);
        }
    };

    const handleRightArrow = () => {
        if (numberOfScroll < count) {
            setNumberOfScroll(numberOfScroll + 1);
            let x = numberOfScroll * cardWidth;
            setScrollX(-x);
            setScrollWidth(scrollWidth + cardWidth);

            if (
                numberOfScroll * cardWidth > pageWidth - 3 * cardWidth &&
                currentTalks.length % 12 === 0 &&
                scrollWidth > currentTalks.length * cardWidth
            ) {
                setPage(page + 1);

                try {
                    if (title === "Populares da semana") {
                        axios.get(`/talks/popular/?p=${page}`).then((res) => {
                            setCurrentTalks([
                                ...currentTalks,
                                ...res.data.talks,
                            ]);
                        });
                    } else if (title === "Sugestões pra você") {
                        axios.get(`/talks/featured/?p=${page}`).then((res) => {
                            const arr = [...currentTalks, ...res.data.talks];
                            setCurrentTalks(arr);
                        });
                    } else if (title === "Transmissões") {
                        axios
                            .get(`/?search=${searchText}&p=${page}`)
                            .then((res) => {
                                const arr = [
                                    ...currentTalks,
                                    ...res.data.talks,
                                ];
                                setCurrentTalks(arr);
                            });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    const startDrag = (e: any) => {
        let clientX = 0;

        if(e.type === "touchstart") {
            clientX = e.touches.length > 0 ? e.touches[0].clientX : 0;
        } else {
            clientX = e.clientX;
        }

        if(!dragging.active) {
            setDragging({
                active: true,
                x: clientX
            });
        }

    }

    const endDrag = (e: any) => {
        const threshold = 50;
        let clientX = 0;

        if(e.type === "touchend") {
            clientX = e.changedTouches.length > 0 ? e.changedTouches[0].clientX : 0;
        } else {
            clientX = e.clientX;
        }

        if(dragging.active) {
            if (clientX - dragging.x > threshold) {
                leftScroll();
            } else if(clientX - dragging.x < -threshold ){
                rightScroll();
            }

            setDragging({active: false, x: 0});
        }
    }

    const leftScroll = () => {
        handleLeftArrow();
    }

    const rightScroll = () => {
        handleRightArrow();

    }


    const whenTalksIsNotEmpty = () => {
        return currentTalks.length > 0;
    };

    return (
        <ContainerCardsCaroussel>
            <div className="container">
                <div style={{ marginBottom: "64px" }}>
                    <div className="header-sub-title">
                        <div className="sub-title">
                            <span>{title}</span>
                        </div>
                        {whenTalksIsNotEmpty() && (
                            <div className="container-arrow">
                                {numberOfScroll - 1 > 0 ? (
                                    <div
                                        className="arrow-active clickable-element"
                                        onClick={handleLeftArrow}
                                    >
                                        <ArrowLeft />
                                    </div>
                                ) : (
                                    <div className="arrow-disabled">
                                        <ArrowLeftDisabled />
                                    </div>
                                )}

                                {numberOfScroll > 0 &&
                                numberOfScroll < count ? (
                                    <div
                                        className="arrow-active clickable-element"
                                        onClick={handleRightArrow}
                                    >
                                        <ArrowRight />
                                    </div>
                                ) : (
                                    <div className="arrow-disabled">
                                        <ArrowRightDisabled />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {description && <div>{description}</div>}
                </div>

                {whenTalksIsNotEmpty() ? (
                    <div className="card-list-area" onTouchStart={startDrag} onTouchEndCapture={endDrag} onMouseDown={startDrag} onMouseUp={endDrag}>
                        <div
                            className="card-list"
                            style={{
                                marginLeft: scrollX,
                                width: count * cardWidth,
                            }}
                        >
                            {currentTalks.map((talk: Talk) => (
                                <Card talk={talk} key={`${description}-${talk.id}`} />
                            ))}
                        </div>
                    </div>
                ) : (
                    <p
                        style={{
                            textAlign: "center",
                            padding: "50px",
                            fontWeight: "bold",
                        }}
                    >
                        Nenhum resultado encontrado.
                    </p>
                )}
            </div>
        </ContainerCardsCaroussel>
    );
};

export default CardsCaroussel;
