import React from "react";
import styled from "styled-components";
import SearchCategories from "../components/Search/SearchCategories";

const Container = styled.div`
    padding: 0 70px;
    @media screen and (max-width: 1200px) {
        padding: 0 40px;
    }
    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }
`;

const SearchCategory = () => {
    return (
        <div>
            <Container>
                <SearchCategories />
            </Container>
        </div>
    );
};

export default SearchCategory;
