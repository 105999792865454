import React from "react";
import Caroussel from "../components/Caroussel";
import Categories from "../components/Categories";
import Container from "../components/Container";
import PopularFromWeek from "../components/PopularFromWeek";
import Suggestions from "../components/Suggestions";

const Home = () => {
    return (
        <div>
            <Caroussel />
            <Container>
                <PopularFromWeek />
                <Suggestions />
                <Categories />
            </Container>
        </div>
    );
};

export default Home;
