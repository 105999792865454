import React, { useContext, useEffect, useState } from "react";
import { Talk } from "../App";
import { DefaultContext } from "../contexts/DefaultContext";
import CardsCaroussel from "./Cards/CardsCaroussel";

const PopularFromWeek = () => {
    const [talks, setTalks] = useState<Talk[]>([]);
    const [count, setCount] = useState<number>(0);
    const { popularFromWeek, popularFromWeekCount } =
        useContext(DefaultContext);

    useEffect(() => {
        setTalks(popularFromWeek);
        setCount(popularFromWeekCount);
    }, [popularFromWeek, popularFromWeekCount]);

    return (
        <CardsCaroussel
            title="Populares da semana"
            description="As transmissões que estão fazendo mais sucesso aqui na Eventials essa semana."
            talks={talks}
            count={count}
        />
    );
};

export default PopularFromWeek;
